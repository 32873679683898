import { markAsReadFailed } from './markAsReadFailed';
import { markAsReadLoading } from './markAsReadLoading';
import { markAsReadSucceed } from './markAsReadSucceed';
import { fetchDesktopMarkAsRead } from '../../../repositories/notification-center/v1/desktop/mark-as-read';
import { TThunkAction } from '../../../types/redux';

export const markAsRead = (id: number): TThunkAction<Promise<void>> => {
  return async (dispatch, _getState, context) => {
    const { httpApi, logger } = context;

    dispatch(markAsReadLoading());
    try {
      await fetchDesktopMarkAsRead({
        httpApi,
        parameters: { ids: [id] },
      });

      dispatch(markAsReadSucceed(id));
    } catch (ex) {
      logger.error(ex, {
        domain: 'markAsReadNotification',
      });

      dispatch(markAsReadFailed());
    }
  };
};
