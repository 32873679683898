import { createConsumer, createSubscriber, getGlobalEventsLogs, IEvent, TSubscriberHandler } from '@cian/events-log';
import { ConfigurationError } from '@cian/peperrors/shared';

import { IAuthenticateEvent, IGeoUpdateRegionEvent, ISetWebPushSubscribedStatusEvent } from './types';
import { modalOpen } from '../../../shared/actions/authentication';
import { setRegionId } from '../../../shared/actions/geo';
import { updateState } from '../../../shared/actions/headerState';
import { setWebPushStatus } from '../../../shared/actions/notifications/webPushSubscription';
import { IApplicationContext } from '../../../shared/types/applicationContext';
import { EWebPushStatus } from '../../../shared/types/notifications';
import { TReduxStore, TThunkDispatch } from '../../../shared/types/redux';

const TOPIC_USER = 'user';
const TOPIC_GEO = 'geo';
const TOPIC_HEADER = 'header';
const TOPIC_PUSH_SUBSCRIPTION = 'push-subscription';

let events: Events | null;

class Events {
  private globalLog = getGlobalEventsLogs();
  private store: TReduxStore;
  /**
   * Использовать глобальные события в качестве API-методов - подход неверный:
   * * Нет удобной обратной связи для вызывающего
   * * Сложно дебажить и искать все источники событий
   *
   * У микрофронтов для этих целей есть API-рантайм,
   * необходимо отключить легаси-способы взаимодействия в новом API шапки,
   * чтобы в будущем нам было легче удалить этот код
   */
  private withEventsAPI: boolean;

  public constructor(_context: IApplicationContext, store: TReduxStore, withEventsAPI: boolean) {
    this.store = store;

    this.withEventsAPI = withEventsAPI;

    const userConsumer = createConsumer({ topic: TOPIC_USER });
    const geoConsumer = createConsumer({ topic: TOPIC_GEO });
    const headerConsumer = createConsumer({ topic: TOPIC_HEADER });
    const pushSubscriptionConsumer = createConsumer({ topic: TOPIC_PUSH_SUBSCRIPTION });

    const userSubscriber = createSubscriber(this.userTopicHandler as TSubscriberHandler);
    const geoSubscriber = createSubscriber(this.geoTopicHandler as TSubscriberHandler);
    const headerSubscriber = createSubscriber(this.headerTopicHandler as TSubscriberHandler);
    const pushSubscriptionSubscriber = createSubscriber(this.pushSubscriptionTopicHandler as TSubscriberHandler);

    userConsumer.subscribe(userSubscriber);
    geoConsumer.subscribe(geoSubscriber);
    headerConsumer.subscribe(headerSubscriber);
    pushSubscriptionConsumer.subscribe(pushSubscriptionSubscriber);
  }

  private geoTopicHandler = (event: IEvent<IGeoUpdateRegionEvent>) => {
    if (this.withEventsAPI && event.type === 'updateRegion' && event.value.regionId !== undefined) {
      this.store.dispatch(setRegionId(event.value.regionId));
    }
  };

  private userTopicHandler = (event: IEvent<IAuthenticateEvent>) => {
    if (this.withEventsAPI && event.type === 'authenticate') {
      const { autoRefresh = true, alert = null, useDefaultView = false, viewData = null, source = null } = event.value;

      this.store.dispatch(modalOpen({ autoRefresh, alert, useDefaultView, viewData, source }));
    }
  };

  private headerTopicHandler = (event: IEvent<{}>) => {
    if (this.withEventsAPI && event.type === 'update') {
      (this.store.dispatch as TThunkDispatch)(updateState());
    }
  };

  private pushSubscriptionTopicHandler = (event: IEvent<ISetWebPushSubscribedStatusEvent>) => {
    if (event.type === 'check-status') {
      this.store.dispatch(
        setWebPushStatus(event.value.subscribed ? EWebPushStatus.Subscribed : EWebPushStatus.Unsubscribed),
      );
    }
  };

  public userAuthenticated = () => {
    this.globalLog.produce(TOPIC_USER, { type: 'authenticated', value: null });
  };

  public authModalClosedNoAuth = () => {
    this.globalLog.produce(TOPIC_USER, {
      type: 'authentication_modal_closed',
      value: null,
    });
  };
}

export function init(context: IApplicationContext, store: TReduxStore, withEventsAPI = true) {
  if (!events) {
    events = new Events(context, store, withEventsAPI);
  }

  return events;
}

export function getEvents() {
  if (!events) {
    throw new ConfigurationError({
      message: 'Events must be created before accessing it. Forgot to call init() before accessing events?',
      domain: 'events.getEvents',
    });
  }

  return events;
}
