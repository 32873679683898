import { ArticleHeading4, ArticleHeading5, ArticleParagraph2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IFavoritesTooltipLayoutProps extends React.PropsWithChildren {
  favoritesLink: string;
  jkLink: string;
  target: '_blank' | '_self';
}

export const FavoritesTooltipLayout: React.FC<IFavoritesTooltipLayoutProps> = ({
  favoritesLink,
  jkLink,
  target,
  children,
}) => {
  return (
    <div className={styles['container']} data-name="FavoritesTooltipLayout">
      <div className={styles['header']}>
        <ArticleHeading4>Избранное</ArticleHeading4>

        <a href={favoritesLink} target={target}>
          <ArticleParagraph2 color="primary_100">Перейти в Избранное</ArticleParagraph2>
        </a>
      </div>

      <div className={styles['links']}>
        <b>
          <ArticleHeading5 color="primary_100">Объявления</ArticleHeading5>
        </b>

        <a href={jkLink} target="_blank" rel="noreferrer">
          <ArticleHeading5 color="black_100">ЖК</ArticleHeading5>
        </a>
      </div>

      {children}
    </div>
  );
};
