import { ca } from '@cian/analytics';

export function trackLogoutClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'top_menu',
    label: 'logout',
  });
}

export const trackClickQualityOfService = (analyticsKey?: string) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'quality_of_service',
    action: 'click',
    label: `top_menu_${analyticsKey}`,
  });
