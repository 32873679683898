import { EHeaderStateActionType } from '../../actions/headerState';
import { TReduxActions } from '../../types/redux';
import { IUserAreaState } from '../../types/userArea';

const defaultState: IUserAreaState = {
  payUrl: '',
  userCabinetUrl: '',
  profileUrl: '',
};

export function userAreaReducer(state: IUserAreaState = defaultState, action: TReduxActions): IUserAreaState {
  switch (action.type) {
    case EHeaderStateActionType.Update:
      return {
        ...action.payload.userArea,
      };
    default:
      return state;
  }
}
