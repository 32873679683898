import { ErrorLogComponent } from '@cian/error-log-component';
import loadable from '@loadable/component';
import * as React from 'react';

import { useMobileAppFullscreenBannerProps } from './useMobileAppFullscreenBannerProps';
import { useApplicationContext } from '../../utils/applicationContext';

const MobileBannerLoadable = loadable.lib(() => import('@cian/mobile-banner-component'));

export function MobileAppFullscreenBannerContainer() {
  const ctx = useApplicationContext();
  const fullscreenBannerProps = useMobileAppFullscreenBannerProps();

  if (!fullscreenBannerProps) {
    return null;
  }

  return (
    <ErrorLogComponent logger={ctx.logger}>
      <MobileBannerLoadable>
        {({ FullscreenBanner }) => <FullscreenBanner {...fullscreenBannerProps} />}
      </MobileBannerLoadable>
    </ErrorLogComponent>
  );
}
