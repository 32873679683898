import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { IMicrofrontendManifest, IRegistry } from '@cian/mf-registry/shared';

import {
  fetchGetChatsWidgetMicrofrontend,
  IGetChatsWidgetMicrofrontendResponse200,
} from '../../repositories/chats-widget-microfrontend';

interface IDependencies {
  httpApi: IHttpApi;
  logger: ILogger;
  microfrontendsRegistry: IRegistry;
}

// FIXME Микрофронт на проде, возвращает контракт не по стандарту. Выпилить в https://jira.cian.tech/browse/CD-203709
interface IMicrofrontendManifestHotfix extends IMicrofrontendManifest {
  manifest: IMicrofrontendManifest;
}

export async function getChatsWidgetMicrofrontend(context: IDependencies): Promise<IMicrofrontendManifest | null> {
  const { httpApi, logger, microfrontendsRegistry } = context;

  try {
    const { statusCode, response } = await fetchGetChatsWidgetMicrofrontend<IGetChatsWidgetMicrofrontendResponse200>({
      httpApi,
      parameters: {},
    });

    if (statusCode !== 200) {
      return null;
    }

    const manifest = (response as IMicrofrontendManifestHotfix).manifest || response;
    microfrontendsRegistry.register(manifest);

    return manifest;
  } catch (ex) {
    logger.error(ex, { domain: 'shared.services.getChatsWidgetMicrofrontend' });

    return null;
  }
}
