import { LinkButton } from '@cian/ui-kit/button';
import { Responsive } from '@cian/ui-kit/responsive';
import * as React from 'react';

import * as styles from './UserRelated.css';
import cubeImg from './assets/cube.png';
import { useGetPlacementProps } from '../../hooks/useGetPlacementProps/useGetPlacementProps';
import { LoginButton } from '../LoginButton';

interface IUserRelatedProps {
  userMenu: JSX.Element | null;
  utilities: JSX.Element;
  placeOfferLink: string | null;
  hasBlockedSanction: boolean;
  onPlaceOfferClick(): void;
  onAuthenticateClick(): void;
  onCianAssistantClick(): void;
  isCianAssistantVisible: boolean;
  mainPageUrl: string;
}

export const UserRelated: React.FC<IUserRelatedProps> = ({
  userMenu,
  utilities,
  placeOfferLink,
  hasBlockedSanction,
  onPlaceOfferClick,
  onAuthenticateClick,
  isCianAssistantVisible,
  onCianAssistantClick,
  mainPageUrl,
}) => {
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  const { text: placementText, url: placementUrl } = useGetPlacementProps();

  return (
    <div className={styles['container']}>
      <div className={styles['utilities']}>{utilities}</div>
      <Responsive tablet desktop>
        {isCianAssistantVisible && (
          <div className={styles['place']}>
            <LinkButton
              href={`${mainPageUrl}/dialogs/?chatbot=1&source=Head&pageType=Other`}
              size="XS"
              theme="fill_white_primary"
              onClick={onCianAssistantClick}
              beforeIcon={<img width="16px" height="16px" src={cubeImg} alt="" />}
            >
              Умный поиск
            </LinkButton>
          </div>
        )}
        {placeOfferLink && !hasBlockedSanction && (
          <div className={styles['place']}>
            <LinkButton
              href={placementUrl || placeOfferLink}
              size="XS"
              onClick={onPlaceOfferClick}
              data-testid="header-add-offer-buttons"
            >
              + {placementText || 'Разместить объявление'}
            </LinkButton>
          </div>
        )}
        {userMenu || <LoginButton onAuthenticateClick={onAuthenticateClick} />}
      </Responsive>
    </div>
  );
};
