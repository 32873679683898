import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackAuthenticateClick, trackPlaceOfferClick, trackCianAssistantClick } from './tracking';
import { modalOpen } from '../../actions/authentication';
import { UserRelated } from '../../components/UserRelated';
import { selectIsCianAssistantExperimentEnabled } from '../../selectors/experiments/selectIsCianAssistantExperimentEnabled';
import { selectPageType } from '../../selectors/page';
import { selectIsRealtor, selectUser } from '../../selectors/user';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { TUserState } from '../../types/user';
import { useApplicationContext } from '../../utils/applicationContext';
import { UserContainer } from '../UserContainer';
import { UtilitiesContainer as UtilitiesContainerRedesign } from '../UtilitiesContainer/Redesign';

export const UserRelatedContainer: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const user = useSelector<IApplicationState, TUserState>(selectUser);
  const isCianAssistantVisible = useSelector(selectIsCianAssistantExperimentEnabled);
  const isRealtor = useSelector(selectIsRealtor);
  const hasBlockedSanction = user.isAuthenticated ? user.hasBlockedSanction : false;
  const pageType = useSelector(selectPageType);
  const placeOfferLink = useSelector<IApplicationState, string | null>(state => state.servicesLinks.placeOffer);
  const {
    custom: { mainPageUrl },
  } = useApplicationContext();

  const handlePlaceOfferClick = React.useCallback(() => trackPlaceOfferClick(pageType), [pageType]);
  const handleCianAssistantClick = React.useCallback(() => trackCianAssistantClick(pageType), [pageType]);
  const handleAuthenticateClick = React.useCallback(() => {
    dispatch(modalOpen());
    trackAuthenticateClick(pageType);
  }, [dispatch, pageType]);

  return (
    <UserRelated
      userMenu={user.isAuthenticated ? <UserContainer /> : null}
      utilities={<UtilitiesContainerRedesign />}
      placeOfferLink={placeOfferLink}
      hasBlockedSanction={hasBlockedSanction}
      onPlaceOfferClick={handlePlaceOfferClick}
      onAuthenticateClick={handleAuthenticateClick}
      isCianAssistantVisible={isCianAssistantVisible && !isRealtor}
      onCianAssistantClick={handleCianAssistantClick}
      mainPageUrl={mainPageUrl}
    />
  );
};
