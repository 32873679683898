import * as Cookies from 'js-cookie';

import { IApplicationContext } from '../../types/applicationContext';

export function getLocationIdFromCookie(context: IApplicationContext) {
  let cookies = context.custom.cookies;

  if (typeof window !== 'undefined') {
    cookies = Cookies.get();
  }

  const userLocationId = cookies['session_region_id'] || cookies['forever_region_id'];

  return userLocationId ? parseInt(`${userLocationId}`, 10) : undefined;
}
