import { modalClose } from './modalClose';
/**
 * @todo пофиксить импорт https://jira.cian.tech/browse/CD-155930
 */
// eslint-disable-next-line import/no-restricted-paths
import { getEvents } from '../../../browser/services/events';
import { TThunkAction } from '../../types/redux';
import { updateState } from '../headerState';

export const userAuthenticated = (): TThunkAction => {
  return (dispatch, getState) => {
    const {
      authentication: { autoRefresh },
    } = getState();

    getEvents().userAuthenticated();

    if (autoRefresh) {
      window.location.reload();
    } else {
      dispatch(modalClose(true));
      dispatch(updateState());
    }
  };
};
