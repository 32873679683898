import { useBooleanState } from '@cian/react-utils';
import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { LoadableError } from '../../../../components/LoadableError';
import { ILoadableErrorProps } from '../../../../types/loadableError';

export const LoadableErrorModal: React.FC<ILoadableErrorProps> = props => {
  const { state: open, setFalse: handleCloseModal } = useBooleanState(true);

  return (
    <ModalWindow
      fixed
      open={open}
      onClose={handleCloseModal}
      title="Что-то пошло не так"
      width="100%"
      maxWidth={540}
      content={<LoadableError {...props} />}
      escape={false}
      size="M"
    />
  );
};
