import loadable from '@loadable/component';
import * as React from 'react';

import { LoadableErrorBoundary } from '../../components/LoadableErrorBoundary';
import { INotificationCenterProps } from '../../components/NotificationCenter';
import { useApplicationContext } from '../../utils/applicationContext';

type TNotificationCenterContainerProps = Pick<INotificationCenterProps, 'onClose'>;

const NotificationCenterInnerLoadable = loadable(
  () => import(/* webpackChunkName: "NotificationCenterInner" */ './loadable'),
  {
    ssr: false,
  },
);

export const NotificationCenterContainer: React.FC<TNotificationCenterContainerProps> = ({ onClose }) => {
  const { logger } = useApplicationContext();

  const handleRetry = React.useCallback(() => {
    NotificationCenterInnerLoadable.load();
  }, []);

  return (
    <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
      <NotificationCenterInnerLoadable onClose={onClose} />
    </LoadableErrorBoundary>
  );
};
