import { ca } from '@cian/analytics';
import { type TProfilingAnalyticsData } from '@cian/frontend-cat-profiling-component';

type TPlatform = 'ios' | 'android';

export const trackShowBanner = (platform?: TPlatform) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'Show',
    category: 'CatProfiling',
    ...(platform !== undefined ? { label: platform === 'ios' ? 'app_iOS' : 'app_android' } : {}),
  });
};

export const trackClickOnBanner = (platform?: TPlatform) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'Click',
    category: 'CatProfiling',
    ...(platform !== undefined ? { label: platform === 'ios' ? 'app_iOS' : 'app_android' } : {}),
    page: {
      extra: {
        buttonName: 'download_app',
      },
    },
  });
};

export const trackOnCloseBanner = (profilingState: TProfilingAnalyticsData, platform?: TPlatform) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'Close',
    category: 'CatProfiling',
    ...(platform !== undefined ? { label: platform === 'ios' ? 'app_iOS' : 'app_android' } : {}),
    page: {
      extra: {
        ...profilingState,
      },
    },
  });
};
