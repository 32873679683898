import * as React from 'react';

import * as styles from './Logout.css';

interface ILogoutProps {
  onClick(): void;
}

export const Logout: React.FC<ILogoutProps> = ({ onClick }) => {
  return (
    <div className={styles['container']} onClick={onClick}>
      <div className={styles['icon']} />
      Выйти
    </div>
  );
};
