import { generateOneLinkUrl } from '@cian/appsflyer-onelink';
import { type TProfilingAnalyticsData, type TProfilingModalProps } from '@cian/frontend-cat-profiling-component';
import { useDeviceType } from '@cian/ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { trackClickOnBanner, trackOnCloseBanner, trackShowBanner } from './tracking';
import { useAllowedCatProfilingPage } from '../../hooks/useAllowedCatProfilingPage';
import { useIsBannerSkippedByCookie } from '../../hooks/useIsBannerSkippedByCookie';
import { selectShouldShowDesktopProfilingBanner } from '../../selectors/profiling/selectShouldShowDesktopProfilingBanner';
import { selectShouldShowMobileProfilingBanner } from '../../selectors/profiling/selectShouldShowMobileProfilingBanner';
import { EMobileDeviceType } from '../../types/mobileDeviceType';
import { useApplicationContext } from '../../utils/applicationContext';

const PROFILING_TITLE = 'Профилирование_через_котиков';
const DEFAULT_PROFILING_APP_URL = 'https://cian.onelink.me/2426377992/lhg7wlpo?af_qr=true';

export function useCatProfilingModalProps(): TProfilingModalProps | null {
  const { config, custom } = useApplicationContext();
  const deviceType = useDeviceType();
  const catProfilingEnabled = useMemo(() => config.get<boolean>('BX.CatProfiling.Enabled') ?? false, [config]);
  const shouldShowMobileProfilingBanner = useSelector(selectShouldShowMobileProfilingBanner);
  const shouldShowDesktopProfilingBanner = useSelector(selectShouldShowDesktopProfilingBanner);
  const isAllowedPage = useAllowedCatProfilingPage();
  const isBannerSkippedByCookie = useIsBannerSkippedByCookie();
  const isWebView = config.get<boolean>('web.isWebView') || false;

  const analiticalPlatform = useMemo<'android' | 'ios' | undefined>(() => {
    switch (custom.mobileDeviceType) {
      case EMobileDeviceType.Android:
        return 'android';
      case EMobileDeviceType.iOS:
        return 'ios';
      default:
        return undefined;
    }
  }, [custom.mobileDeviceType]);

  const [url, setUrl] = useState<string | null>(
    config.get<string>('BX.CatProfiling.AppLink') ?? DEFAULT_PROFILING_APP_URL,
  );

  const handleClose = useCallback(
    (profilingState: TProfilingAnalyticsData) => {
      trackOnCloseBanner(profilingState, analiticalPlatform);
    },
    [analiticalPlatform],
  );

  const handleOpen = useCallback(() => {
    trackShowBanner(analiticalPlatform);
  }, [analiticalPlatform]);

  const handleDownload = useCallback(() => {
    trackClickOnBanner(analiticalPlatform);
  }, [analiticalPlatform]);

  useEffect(() => {
    setUrl(generateOneLinkUrl(url, 'mobile_site', PROFILING_TITLE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => {
      if (
        !catProfilingEnabled ||
        !url ||
        !(
          (shouldShowMobileProfilingBanner &&
            (deviceType === 'phone' || deviceType === 'tablet') &&
            custom.mobileDeviceType !== EMobileDeviceType.Unknown) ||
          (shouldShowDesktopProfilingBanner && (deviceType === 'desktop' || deviceType === 'tablet'))
        ) ||
        !isAllowedPage ||
        isBannerSkippedByCookie ||
        isWebView
      ) {
        return null;
      }

      return {
        deviceType,
        downloadLink: url,
        onClose: handleClose,
        onOpen: handleOpen,
        onDownload: handleDownload,
      };
    },
    // специально исключаем из зависимостей deviceType, чтобы не перерендеривать компонент
    // при изменении ширины экрана
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      catProfilingEnabled,
      custom.mobileDeviceType,
      handleClose,
      handleDownload,
      handleOpen,
      isAllowedPage,
      isBannerSkippedByCookie,
      isWebView,
      shouldShowDesktopProfilingBanner,
      shouldShowMobileProfilingBanner,
      url,
    ],
  );
}
