import { ErrorLogComponent } from '@cian/error-log-component';
import loadable from '@loadable/component';
import * as React from 'react';

import { usePinnedButtonProps } from './usePinnedButtonProps';
import { useApplicationContext } from '../../utils/applicationContext';

const MobileBannerLoadable = loadable.lib(() => import('@cian/mobile-banner-component'));

export function PinnedButtonContainer() {
  const { logger } = useApplicationContext();
  const props = usePinnedButtonProps();

  if (!props) {
    return null;
  }

  return (
    <ErrorLogComponent logger={logger}>
      <MobileBannerLoadable>{({ PinnedButton }) => <PinnedButton {...props} />}</MobileBannerLoadable>
    </ErrorLogComponent>
  );
}
