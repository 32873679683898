import * as React from 'react';

import * as styles from './ServiceItem.css';
import { ICONS } from './constants';

interface IServiceItemProps {
  icon: keyof typeof ICONS;
  href: string;
  target?: string;
  onClick?(): void;
  children: React.ReactNode;
  dataTestid?: string;
}

export const ServiceItem: React.FC<IServiceItemProps> = ({ icon, href, target, children, dataTestid, onClick }) => {
  return (
    <li className={styles['container']}>
      <a href={href} target={target} onClick={onClick} data-testid={dataTestid}>
        <div className={styles['icon']} style={{ backgroundImage: `url("${ICONS[icon]}")` }} />
        {children}
      </a>
    </li>
  );
};
