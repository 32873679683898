import loadable from '@loadable/component';
import * as React from 'react';

import { LoadableFallback } from '../../../LoadableFallback';

export const CookiesAgreementModalContentLoadable = loadable(
  () => import(/* webpackChunkName: "CookiesAgreementModalContent" */ './CookiesAgreementModalContent'),
  {
    fallback: <LoadableFallback />,
  },
);
