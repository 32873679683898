import * as React from 'react';

import * as styles from './styles.css';

interface IMobileAppLinkLayoutProps extends React.PropsWithChildren {
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
}

export const MobileAppLinkLayout: React.FC<IMobileAppLinkLayoutProps> = ({ onClick, children }) => {
  return (
    <a className={styles['link']} href={`/mobile-app/`} onClick={onClick}>
      {children}
    </a>
  );
};
