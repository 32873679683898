import { IconActionMessageLine16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackChatsIconClick } from './tracking';
import { Utility } from '../../components/Utility';
import { IApplicationState } from '../../types/redux';

export const UtilityMessagesContainer: React.FC = () => {
  const messagesLink = useSelector<IApplicationState, string>(state => state.servicesLinks.messages);
  const messagesCount = useSelector<IApplicationState, number>(state => state.messages.count);

  return (
    <Utility
      data-name="UtilityMessagesContainer"
      title="Сообщения"
      unread={Boolean(messagesCount)}
      onClick={trackChatsIconClick}
      icon={IconActionMessageLine16}
      href={messagesLink}
    />
  );
};
