import { ILogger } from '@cian/logger';
import * as React from 'react';

import { ILoadableErrorProps } from '../../types/loadableError';
import { LoadableError } from '../LoadableError';

interface ILoadableErrorBoundaryProps {
  logger?: ILogger;
  fallback?: React.ComponentType<ILoadableErrorProps>;
  onRetry(): void;
  children?: React.ReactNode;
}

interface ILoadableErrorBoundaryState {
  hasError: boolean;
}

export class LoadableErrorBoundary extends React.Component<ILoadableErrorBoundaryProps, ILoadableErrorBoundaryState> {
  public state: ILoadableErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(): ILoadableErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (this.props.logger) {
      this.props.logger.error(error, { ...info });
    }
  }

  public render() {
    if (this.state.hasError) {
      const Component = this.props.fallback || LoadableError;

      return <Component onRetry={this.handleRetryClick} />;
    }

    return this.props.children;
  }

  private handleRetryClick = () => {
    this.props.onRetry();

    this.setState({
      hasError: false,
    });
  };
}
