import * as React from 'react';

import { FeedbackFormContentContainerLoadable } from './FeedbackFormContentContainerLoadable';
import { FeedbackFormTechProblemsContainer } from './containers/FeedbackFormTechProblemsContainer';
import { useApplicationContext } from '../../utils/applicationContext';

export const FeedbackFormContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const techProblemsBannerEnabled = config.get('feedbackForm.techProblemsBanner.enabled');

  return (
    <>
      {techProblemsBannerEnabled && <FeedbackFormTechProblemsContainer />}
      {!techProblemsBannerEnabled && <FeedbackFormContentContainerLoadable />}
    </>
  );
};
