import { ENotificationsActionTypes } from './types';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export const openNotificationsModal = actionGenerator<ENotificationsActionTypes.OpenNotificationsModal, boolean>(
  ENotificationsActionTypes.OpenNotificationsModal,
);

export const openNotificationsModalAfterAuth =
  actionGenerator<ENotificationsActionTypes.OpenNotificationsModalAfterAuth>(
    ENotificationsActionTypes.OpenNotificationsModalAfterAuth,
  );
