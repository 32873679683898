import { TooltipDesktop } from '@cian/ui-kit';
import { IconToggleHeartOff16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openNotificationsModal } from '../../../actions/notifications/openModal';
import { Utility as UtilityRedesign } from '../../../components/Utility/Redesign';
import { IApplicationState } from '../../../types/redux';
import { FavoritesTooltipContainer } from '../../FavoritesTooltip';
import { trackFavoritesIconClick } from '../tracking';

export const UtilityFavoritesContainer: React.FC = () => {
  const [isMainPage, setIsMainPage] = React.useState(false);

  const dispatch = useDispatch();

  const favoritesLink = useSelector<IApplicationState, string | null>(state => state.servicesLinks.favorites);
  const favoritesCount = useSelector<IApplicationState, number>(state => state.favorites.count);

  const handleClick = React.useCallback(() => {
    trackFavoritesIconClick();
  }, []);

  const handleOpen = React.useCallback(() => {
    dispatch(openNotificationsModal(false));
  }, [dispatch]);

  React.useEffect(() => {
    // Открываем вкладку Избранного в той же, если переход с главной страницы
    setIsMainPage(window.location.pathname === '/');
  }, []);

  if (!favoritesLink) {
    return null;
  }

  return (
    <TooltipDesktop
      onOpen={handleOpen}
      placement={favoritesCount ? 'bottom-end' : 'bottom'}
      theme={favoritesCount ? 'white' : 'black'}
      title={favoritesCount ? <FavoritesTooltipContainer target={isMainPage ? '_self' : '_blank'} /> : 'Избранное'}
    >
      <div data-testid="utility-title" data-name="UtilityFavoritesContainer">
        <UtilityRedesign
          title="Избранное"
          icon={IconToggleHeartOff16}
          href={favoritesLink}
          onClick={handleClick}
          target={isMainPage ? '_self' : '_blank'}
          withoutTitle={true}
        />
      </div>
    </TooltipDesktop>
  );
};
