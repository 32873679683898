import { ca } from '@cian/analytics';

export function trackSavedSearchesIconClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'top_menu',
    label: 'saved_search',
  });
}
