export interface IRubricsLinksState {
  askAgent: string | null;
  businessAnalytics: string | null;
  businessCenters: string | null;
  commercial: string | null;
  howToSaleSecondaryApartment: string | null;
  investCalculator: string | null;
  magazine: string | null;
  magazineAnalytics: string | null;
  magazineApartmentEvaluation: string | null;
  magazineDisountMortgage: string | null;
  magazineDocuments: string | null;
  magazineMortgage: string | null;
  magazineNewbuildingVsSecondary: string | null;
  magazineOnlineDeal: string | null;
  magazineRent: string | null;
  magazineSuburban: string | null;
  marketAnalytics: string | null;
  mortgage: string | null;
  mortgageCalculator: string | null;
  mortgageCenter: string | null;
  mortgageConsumerCredit: string | null;
  mortgageGovHelp: string | null;
  mortgagePersonalOffers: string | null;
  mortgageZeroOne: string | null;
  mortgageBrokerCalculator: string | null;
  mortgageBrokerPromo: string | null;
  newbuildingPromosUrl: string | null;
  newbuildingServicesKpn: string | null;
  rent: string | null;
  rentCottage: string | null;
  rentCoworking: string | null;
  rentDaily: string | null;
  rentOffice: string | null;
  rentRoom: string | null;
  rentShoppingArea: string | null;
  rentWarehouse: string | null;
  residentialComplexes: string | null;
  residentialComplexesCalculator: string | null;
  residentialComplexesCatalog: string | null;
  sale: string | null;
  saleApartment: string | null;
  saleCottage: string | null;
  saleLandplot: string | null;
  saleOffice: string | null;
  saleReadyBusiness: string | null;
  saleResidentialComplexesApartment: string | null;
  saleRoom: string | null;
  saleSecondaryApartment: string | null;
  saleShoppingArea: string | null;
  saleWarehouse: string | null;
  shoppingCenters: string | null;
  evaluation: string | null;
  villages: string | null;
  warehouses: string | null;
  suburbanMortgage: string | null;
  realtorSelection: string | null;
  promotions: string | null;
  dealMain: string | null;
  legalCheck: string | null;
  countryside: string | null;
  suburban: string | null;
  townhouseSale: string | null;
  landplotSale: string | null;
  cottageSale: string | null;
  countrysideRent: string | null;
  countrysideKp: string | null;
  landSale: string | null;
  houseSale: string | null;
  rentAddform: string | null;
  saleAddform: string | null;
  suburbanAddform: string | null;
  suburbanBuilders: string | null;
  suburbanBuildersProjects: string | null;
  suburbanMortgageLanding: string | null;
  suburbanTechInspection: string | null;
  buildingApplication: string | null;
  layoutsCatalog: string | null;
}

export enum ERubric {
  Rent = 'rent',
  Sale = 'sale',
  ResidentialComplexes = 'residentialComplexes',
  Countryside = 'countryside',
  Commercial = 'commercial',
  Evaluation = 'evaluation',
  Mortgage = 'mortgage',
  MyHome = 'myHome',
  Services = 'services',
  CianApp = 'cianApp',
  Extra1024 = 'extra1024',
  Extra1093 = 'extra1093',
  Extra1242 = 'extra1242',
  OldExtra1024 = 'oldExtra1024',
  OldExtra1093 = 'oldExtra1093',
}
