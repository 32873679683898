import { TooltipDesktop } from '@cian/ui-kit/tooltip';
import { IIconProps } from '@cian/ui-kit-design-tokens/icons';
import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './Utility.css';

interface IUtilityProps {
  title: string;
  icon: React.ComponentType<IIconProps>;
  unread?: boolean;
}

interface IUtilityButtonProps extends IUtilityProps {
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

interface IUtilityLinkProps extends IUtilityProps {
  href?: string;
  target?: string;
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
}

type TUtilityProps = IUtilityButtonProps | IUtilityLinkProps;

export const Utility: React.FC<TUtilityProps> = props => {
  const { title, icon: Icon, unread, onClick, ...rest } = props;

  const Element = isLinkUtility(props) ? 'a' : 'button';
  const linkExtraProps = isLinkUtility(props)
    ? {
        target: props.target || '_blank',
        rel: 'noreferrer',
      }
    : {};

  return (
    <TooltipDesktop placement="bottom" title={title}>
      <Element
        {...rest}
        className={clsx(styles['button'], { [styles['button--unread']]: unread })}
        onClick={onClick as React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>}
        {...linkExtraProps}
      >
        <Icon display="block" color="current_color" />
      </Element>
    </TooltipDesktop>
  );
};

function isLinkUtility(props: TUtilityProps): props is IUtilityLinkProps {
  return 'href' in props;
}
