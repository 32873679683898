import { EAuthenticationActionType } from '../../actions/authentication';
import { EDeleteAllNotificationActionTypes } from '../../actions/notifications/deleteAll';
import { EGetNotificationsListActionTypes } from '../../actions/notifications/getNotificationsList';
import { EMarkAllAsReadNotificationActionTypes } from '../../actions/notifications/markAllAsRead';
import { EMarkAsReadNotificationActionTypes } from '../../actions/notifications/markAsRead';
import { ENotificationsActionTypes } from '../../actions/notifications/types';
import { ENotificationsStatus, EWebPushStatus, INotificationsState } from '../../types/notifications';
import { TReduxActions } from '../../types/redux';

export const defaultState = {
  count: 0,
  notifications: [],
  webPushStatus: EWebPushStatus.Subscribed,
  status: ENotificationsStatus.Initial,
  isNotificationsModalOpen: false,
  openAfterAuth: false,
};

export function notificationsReducer(
  state: INotificationsState = defaultState,
  action: TReduxActions,
): INotificationsState {
  switch (action.type) {
    case EGetNotificationsListActionTypes.Loading:
    case EMarkAsReadNotificationActionTypes.Loading:
    case EMarkAllAsReadNotificationActionTypes.Loading:
    case EDeleteAllNotificationActionTypes.Loading:
      return {
        ...state,
        status: ENotificationsStatus.Loading,
      };

    case EGetNotificationsListActionTypes.Succeed:
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload],
        status: ENotificationsStatus.Succeed,
      };

    case EMarkAsReadNotificationActionTypes.Succeed:
      return {
        ...state,
        notifications: state.notifications.map(note => (note.id === action.payload ? { ...note, isRead: true } : note)),
        status: ENotificationsStatus.Succeed,
      };

    case EMarkAllAsReadNotificationActionTypes.Succeed:
      return {
        ...state,
        count: 0,
        notifications: state.notifications.map(note => ({ ...note, isRead: true })),
        status: ENotificationsStatus.Succeed,
      };

    case EDeleteAllNotificationActionTypes.Succeed:
      return {
        ...state,
        notifications: [],
        status: ENotificationsStatus.Succeed,
      };

    case EGetNotificationsListActionTypes.Failed:
    case EMarkAsReadNotificationActionTypes.Failed:
    case EMarkAllAsReadNotificationActionTypes.Failed:
    case EDeleteAllNotificationActionTypes.Failed:
      return {
        ...state,
        status: ENotificationsStatus.Failed,
      };
    case ENotificationsActionTypes.UpdateCount:
      return {
        ...state,
        status: ENotificationsStatus.Initial,
        notifications: [],
        count: action.payload,
      };
    case ENotificationsActionTypes.SetWebPushStatus:
      return {
        ...state,
        webPushStatus: action.payload,
      };
    case ENotificationsActionTypes.OpenNotificationsModal:
      return {
        ...state,
        isNotificationsModalOpen: action.payload,
        openAfterAuth: false,
      };
    case ENotificationsActionTypes.OpenNotificationsModalAfterAuth:
      return {
        ...state,
        openAfterAuth: true,
      };
    case EAuthenticationActionType.Succeed: {
      if (!state.openAfterAuth) {
        return state;
      }

      return {
        ...state,
        isNotificationsModalOpen: true,
        openAfterAuth: false,
      };
    }

    case EAuthenticationActionType.Cancelled:
      return {
        ...state,
        openAfterAuth: false,
      };
    default:
      return state;
  }
}
