import * as React from 'react';

import * as styles from './DropdownInner.css';

interface IDropdownInnerProps {
  dropdownMain: JSX.Element;
  dropdownBottom: JSX.Element;
}

export const DropdownInner: React.FC<IDropdownInnerProps> = ({ dropdownMain, dropdownBottom }) => {
  return (
    <div className={styles['dropdown']}>
      {dropdownMain}
      {dropdownBottom}
    </div>
  );
};
