import { EFavoritesActionTypes } from '../../actions/favorites';
import { EHeaderStateActionType } from '../../actions/headerState';
import { IFavoritesState } from '../../types/favorites';
import { TReduxActions } from '../../types/redux';

const defaultState: IFavoritesState = {
  count: 0,
  favoriteItems: [],
};

export function favoritesReducer(state: IFavoritesState = defaultState, action: TReduxActions): IFavoritesState {
  switch (action.type) {
    case EHeaderStateActionType.Update:
      return {
        ...state,
        count: action.payload.favorites.count,
      };

    case EFavoritesActionTypes.UpdateUserFavorites:
      return {
        ...state,
        favoriteItems: action.payload,
      };

    default:
      return state;
  }
}
