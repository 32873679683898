import { createApplicationContext } from '../applicationContext/createApplicationContext';
import { createApplicationState } from '../applicationState';
import { init as initAuthContext } from '../authContext';
import { init as initEvents } from '../events';
import { initLegacyCallbacks } from '../legacyCallbacks';
import { renderApplication } from '../renderApplication';

export function runApp() {
  const context = createApplicationContext();
  const store = createApplicationState(context);

  initAuthContext(context, store);
  initEvents(context, store);
  initLegacyCallbacks();

  renderApplication({ store, context });
}
