// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TDesktopMarkAllAsReadModel,
  IMappers,
  TDesktopMarkAllAsReadResponse,
  TDesktopMarkAllAsReadResponse_1,
  TDesktopMarkAllAsReadResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TDesktopMarkAllAsReadModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'notification-center',
  pathApi: '/v1/desktop/mark-all-as-read/',
} as TDesktopMarkAllAsReadModel;

export function createDesktopMarkAllAsReadModel(): TDesktopMarkAllAsReadModel {
  return {
    ...defaultConfig,
  };
}

export interface IDesktopMarkAllAsReadOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

export async function fetchDesktopMarkAllAsRead<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
}: IDesktopMarkAllAsReadOptions<TResponse200, TResponse400>): Promise<
  TDesktopMarkAllAsReadResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createDesktopMarkAllAsReadModel(), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TDesktopMarkAllAsReadResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TDesktopMarkAllAsReadResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TDesktopMarkAllAsReadResponse;
}
