import { createStore, applyMiddleware, CombinedState, Action, Store, Reducer, PreloadedState } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { createComposeEnhancers } from '../createComposeEnhancers';

interface IMakeCreateStoreParams<S, A extends Action> {
  name: string;
  reducer: Reducer<CombinedState<S>, A>;
}

export function makeCreateStore<S, E, A extends Action>({ name, reducer }: IMakeCreateStoreParams<S, A>) {
  const composeEnhancers = createComposeEnhancers({ name });

  interface IThunkExt {
    dispatch: ThunkDispatch<S, E, A>;
  }

  return function createReduxStore(initialState: PreloadedState<CombinedState<S>>, context: E): Store<S, A> {
    const storeEnchancers = composeEnhancers(applyMiddleware(thunk.withExtraArgument(context)));

    return createStore<CombinedState<S>, A, IThunkExt, {}>(reducer, initialState, storeEnchancers);
  };
}
