import * as Cookies from 'js-cookie';

import { getTrackingUser, trackCookieAgreementlick } from './tracking';
import { ECookiesActionTypes, TCookiesAccepted } from './types';
import { COOKIES_AGREEMENT_ACCEPTED_KEY } from '../../constants/cookies';
import { TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export const cookiesAccepted: () => TCookiesAccepted = actionGenerator<ECookiesActionTypes.CookiesAccepted>(
  ECookiesActionTypes.CookiesAccepted,
);

export const acceptCookies = (): TThunkAction => (dispatch, getState) => {
  const user = getTrackingUser(getState());

  Cookies.set(COOKIES_AGREEMENT_ACCEPTED_KEY, '1', {
    domain: '.cian.ru',
    expires: 365,
  });

  trackCookieAgreementlick(user);

  dispatch(cookiesAccepted());
};
