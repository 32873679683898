import { ELogoutActionTypes } from '../../actions/logout';
import { ELogoutStatus, ILogoutState } from '../../types/logout';
import { TReduxActions } from '../../types/redux';

const defaultState: ILogoutState = {
  status: ELogoutStatus.Initial,
};

export function logoutReducer(state: ILogoutState = defaultState, action: TReduxActions): ILogoutState {
  switch (action.type) {
    case ELogoutActionTypes.Loading:
      return {
        status: ELogoutStatus.Loading,
      };

    case ELogoutActionTypes.Succeed:
      return {
        status: ELogoutStatus.Succeed,
      };

    case ELogoutActionTypes.Failed:
      return {
        status: ELogoutStatus.Failed,
      };
  }

  return state;
}
