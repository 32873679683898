import { IconMapCar16, IconMapWalk16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { MetroItem } from '../../../components/Favorites';
import { IGeoForDesktopSchema } from '../../../repositories/favorites/entities/favorite_entity_for_desktop/GeoForDesktopSchema';
import { ETransportType } from '../../../repositories/favorites/entities/favorite_entity_for_desktop/UndergroundInfoForDesktopSchema';

export const getMetroComponent = (undergrounds: IGeoForDesktopSchema['undergrounds']): React.ReactNode | null => {
  const metroItem = undergrounds.find(({ isDefault }) => isDefault);

  if (!metroItem) {
    return null;
  }

  return (
    <MetroItem
      name={metroItem.name}
      timeToDirection={`${metroItem.time} мин`}
      metroLineColor={metroItem.lineColor ?? '999999'}
      transportTypeIcon={metroItem.transportType === ETransportType.Walk ? <IconMapWalk16 /> : <IconMapCar16 />}
    />
  );
};
