import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { disableLoadForm } from '../../../../actions/feedbackForm';
import { LoadableFallback } from '../../../../components/LoadableFallback';
import { TThunkDispatch } from '../../../../types/redux';
import { DEFERRED_KEY } from '../../constants';
import { getDeferredRegistry } from '../../utils/deferred';

export const LoadableFallbackModal: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const handleCloseModal = React.useCallback(() => {
    const registry = getDeferredRegistry();

    const value = registry.get(DEFERRED_KEY);
    if (value) {
      value.reject('Загрузка формы обратной связи прервана пользователем');
      registry.remove(DEFERRED_KEY);
    }

    dispatch(disableLoadForm());
  }, [dispatch]);

  return (
    <ModalWindow
      fixed
      open={true}
      onClose={handleCloseModal}
      title="Напишите нам"
      width="100%"
      maxWidth={540}
      content={<LoadableFallback />}
      escape={false}
      size="M"
    />
  );
};
