import { IApplicationState } from '../../types/redux';

type TUserBaseInfo = {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  isAuthenticated: boolean;
};

export function selectUserBaseInfo({ user }: IApplicationState): TUserBaseInfo {
  if (user.isAuthenticated) {
    return {
      email: user.email || '',
      phone: user.phone || '',
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      isAuthenticated: user.isAuthenticated,
    };
  }

  return {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    isAuthenticated: user.isAuthenticated,
  };
}
