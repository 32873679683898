import { ResponseError } from '../../errors';
import { IHeaderData } from '../../headerData';
import { prepareHeaderData } from '../../mappers/prepareHeaderData';
import { fetchGetHeader, TGetHeaderResponse } from '../../repositories/header/v1/get-header';
import { IApplicationContext } from '../../types/applicationContext';

interface IGetHeaderDataParameters {
  /** ID региона пользователя **/
  locationId?: number;
  /** Поддомен **/
  subdomain?: string;
}

export async function getHeaderData(
  context: IApplicationContext,
  parameters: IGetHeaderDataParameters = {},
): Promise<IHeaderData> {
  const { httpApi } = context;

  const headerData: TGetHeaderResponse = await fetchGetHeader({
    httpApi,
    parameters: {
      ...parameters,
    },
  });

  if (headerData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'getHeaderData',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      message: headerData.response.message!,
      details: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: headerData.response.errors as any,
      },
    });
  }

  return prepareHeaderData(context, headerData.response);
}
