import { CustomTooltip } from '@cian/ui-kit';
import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './SpecialPromoDesktop.css';

export interface ISpecialPromoDesktopProps {
  href: string;
  logoUrl: string | null;
  title?: string;
  disclaimer: string | null;

  onClick(): void;
}

export const SpecialPromoDesktop: React.FC<ISpecialPromoDesktopProps> = ({
  logoUrl,
  onClick,
  href,
  title,
  disclaimer,
}) => {
  const [isHintOpened, setHintOpened] = React.useState(false);
  const hintRef = React.useRef<HTMLDivElement>(null);

  return (
    <a
      data-testid="special-promo-logo"
      href={href}
      onClick={onClick}
      className={styles['link']}
      onMouseEnter={() => setHintOpened(true)}
      onMouseLeave={() => setHintOpened(false)}
    >
      {logoUrl && (
        <span
          ref={hintRef}
          data-testid="special-promo-logo-image"
          className={clsx(styles['icon'], !!title && styles['icon--with-title'])}
          style={{
            backgroundImage: `url(${logoUrl})`,
          }}
        />
      )}
      {title && (
        <span data-testid="special-promo-name-title" className={styles['title']}>
          {title}
        </span>
      )}
      {disclaimer && (
        <CustomTooltip open={isHintOpened} placement="bottom-start" content={disclaimer} anchorRef={hintRef} />
      )}
    </a>
  );
};

SpecialPromoDesktop.displayName = 'SpecialPromoDesktop';
