import { generateOneLinkUrl } from '@cian/appsflyer-onelink';
import { type IFullscreenBannerProps } from '@cian/mobile-banner-component';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { MOBILE_BANNER_TITLE } from './constants';
import { trackClickOnBanner, trackOnCloseBanner, trackShowBanner } from './tracking';
import { useAllowedMobileAppBannerPage } from '../../hooks/useAllowedMobileAppBannerPage';
import { useIsBannerSkippedByCookie } from '../../hooks/useIsBannerSkippedByCookie';
import { useNewMobileAppBannerEntryPoint } from '../../hooks/useNewMobileAppBannerEntryPoint';
import { selectMobileBannerCookieState } from '../../selectors/cookies/selectMobileBannerCookieState';
import { selectShouldShowMobileProfilingBanner } from '../../selectors/profiling/selectShouldShowMobileProfilingBanner';
import { EMobileDeviceType } from '../../types/mobileDeviceType';
import { useApplicationContext } from '../../utils/applicationContext';

export function useMobileAppFullscreenBannerProps(): IFullscreenBannerProps | null {
  const { config, custom } = useApplicationContext();
  const headerEntryPointEnabled = useNewMobileAppBannerEntryPoint();
  const mobileBannerEnabled = config.get<boolean>('mobileBanner.enabled') ?? false;
  const isWebView = config.get<boolean>('web.isWebView') || false;
  const mobileBannerShown = useSelector(selectMobileBannerCookieState);
  const shouldShowProfilingBanner = useSelector(selectShouldShowMobileProfilingBanner);
  const isAllowedPage = useAllowedMobileAppBannerPage();
  const isBannerSkippedByCookie = useIsBannerSkippedByCookie();

  const [url, setUrl] = useState<string | null>(config.get<string>('mobileBanner.browserUrl') || null);

  useEffect(() => {
    setUrl(generateOneLinkUrl(url, 'mobile_site', MOBILE_BANNER_TITLE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBannerClick = useCallback(() => {
    trackClickOnBanner(custom.mobileDeviceType);
  }, [custom.mobileDeviceType]);

  const handleCloseBanner = useCallback(() => {
    trackOnCloseBanner(custom.mobileDeviceType);
  }, [custom.mobileDeviceType]);

  const handleShowBanner = useCallback(() => {
    trackShowBanner(custom.mobileDeviceType);
  }, [custom.mobileDeviceType]);

  return useMemo(() => {
    if (
      !mobileBannerEnabled ||
      !url ||
      !headerEntryPointEnabled ||
      mobileBannerShown ||
      shouldShowProfilingBanner ||
      custom.mobileDeviceType === EMobileDeviceType.Unknown ||
      !isAllowedPage ||
      isBannerSkippedByCookie ||
      isWebView
    ) {
      return null;
    }

    return {
      url,
      // mobileAppUrl - был нужен для экспа, передавть более не нужно, но свойство required
      mobileAppUrl: '/mobile-app/',
      // caMobileAppClick - был нужен для экспа, передавть более не нужно, но свойство required
      caMobileAppClick: () => {},
      caShowBanner: handleShowBanner,
      caClickOnBanner: handleBannerClick,
      caCloseBanner: handleCloseBanner,
    };
  }, [
    custom.mobileDeviceType,
    handleBannerClick,
    handleCloseBanner,
    handleShowBanner,
    headerEntryPointEnabled,
    isAllowedPage,
    isBannerSkippedByCookie,
    isWebView,
    mobileBannerEnabled,
    mobileBannerShown,
    shouldShowProfilingBanner,
    url,
  ]);
}
