import { Responsive } from '@cian/ui-kit/responsive';
import * as React from 'react';

import * as styles from './Services.css';
import { ServiceItem } from './components/ServiceItem';
import { useMyHomeProps } from '../../../../containers/MyHomeDesktopContainer';
import { useGetPlacementProps } from '../../../../hooks/useGetPlacementProps/useGetPlacementProps';
import { IFavoritesState } from '../../../../types/favorites';
import { IMessagesState } from '../../../../types/messages';
import { INotificationsState } from '../../../../types/notifications';
import { ISavedSearchesState } from '../../../../types/savedSearches';
import { IServicesLinksState } from '../../../../types/servicesLinks';
import { Badge } from '../../../Badge';

export interface IServicesProps {
  isAuthenticated: boolean;
  hasBlockedSanction: boolean;
  isE2eDealVisible: boolean;
  isTechInspectionAvailable: boolean;
  links: IServicesLinksState;
  savedSearches: ISavedSearchesState;
  messages: IMessagesState;
  favorites: IFavoritesState;
  notifications: INotificationsState;
  onServiceClick(service: string): void;
}

export const Services: React.FC<IServicesProps> = ({
  isAuthenticated,
  hasBlockedSanction,
  isE2eDealVisible,
  isTechInspectionAvailable,
  links,
  savedSearches,
  messages,
  favorites,
  notifications,
  onServiceClick,
}) => {
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  const { text: placementText, url: placementUrl } = useGetPlacementProps();
  const { valuationServiceName, valuationServiceLabel, valuationServiceUrl } = useMyHomeProps();

  return (
    <ul className={styles['container']}>
      <ServiceItem icon="search" href={links.search}>
        Новый поиск
      </ServiceItem>
      {links.placeOffer && (
        <Responsive phone>
          <ServiceItem
            icon="placeOffer"
            href={placementUrl || links.placeOffer}
            onClick={() => onServiceClick('add_adv')}
            dataTestid="header-add-offer-button"
          >
            {placementText || 'Разместить объявление'}
          </ServiceItem>
        </Responsive>
      )}
      {links.savedSearches && !hasBlockedSanction && (
        <ServiceItem icon="savedSearches" href={links.savedSearches} onClick={() => onServiceClick('savedSearch')}>
          Сохранённые поиски
          {savedSearches.count > 0 && (
            <span data-testid="savedSearches-count" className={styles['postfix']}>
              <Badge pill>{savedSearches.count}</Badge>
            </span>
          )}
        </ServiceItem>
      )}
      {links.favorites && (
        <ServiceItem icon="favorites" href={links.favorites} onClick={() => onServiceClick('favorites')}>
          Избранное
          {favorites.count > 0 && (
            <span data-testid="favorites-count" className={styles['postfix']}>
              <Badge pill>{favorites.count}</Badge>
            </span>
          )}
        </ServiceItem>
      )}
      {isAuthenticated && links.notifications && (
        <ServiceItem icon="notifications" href={links.notifications} target="_blank">
          Уведомления
          {notifications.count > 0 && (
            <span data-testid="notifications-count" className={styles['postfix']}>
              <Badge pill>{notifications.count}</Badge>
            </span>
          )}
        </ServiceItem>
      )}

      <ServiceItem icon="messages" href={links.messages} onClick={() => onServiceClick('messages')}>
        Сообщения
        {messages.count > 0 && (
          <span data-testid="messages-count" className={styles['postfix']}>
            <Badge pill>{messages.count}</Badge>
          </span>
        )}
      </ServiceItem>
      {links.mortgageBroker && (
        <ServiceItem icon="e2eMortgage" href={links.mortgageBroker}>
          Мои ипотечные заявки
          <span data-testid="e2e-mortgage-badge" className={styles['postfix']}>
            <Badge>New</Badge>
          </span>
        </ServiceItem>
      )}
      {isAuthenticated && links.deals && isE2eDealVisible && (
        <ServiceItem icon="deals" href={links.deals}>
          Циан.Сделка
        </ServiceItem>
      )}
      {links.helpCenter && (
        <ServiceItem icon="helpCenter" href={links.helpCenter} target="_blank">
          Справочный центр
        </ServiceItem>
      )}
      <ServiceItem icon="reportProblem" href={links.reportProblem}>
        Сообщить об ошибке
      </ServiceItem>
      {isAuthenticated && links.complaints && (
        <ServiceItem icon="complaints" href={links.complaints}>
          Жалобы
        </ServiceItem>
      )}
      {isAuthenticated && links.hiddenOffers && (
        <ServiceItem icon="hiddenOffers" href={links.hiddenOffers}>
          Скрытые объявления
        </ServiceItem>
      )}
      {links.mortgageForm && (
        <ServiceItem icon="mortgageForm" href={links.mortgageForm} target="_blank">
          Ипотечная анкета
        </ServiceItem>
      )}
      {links.mortgageCalculator && (
        <ServiceItem icon="mortgageCalculator" href={links.mortgageCalculator} target="_blank">
          Ипотечный калькулятор
        </ServiceItem>
      )}
      {links.agentHelpRequest && (
        <ServiceItem icon="requestAgentHelp" href={links.agentHelpRequest}>
          Заявка на помощь риелтора
        </ServiceItem>
      )}
      <ServiceItem icon="saleRent" href={links.saleRent}>
        Куплю / Сниму
      </ServiceItem>
      <ServiceItem
        icon="flatValuation"
        href={valuationServiceUrl}
        onClick={() => onServiceClick(valuationServiceLabel)}
      >
        {valuationServiceName}
      </ServiceItem>
      {isTechInspectionAvailable && links.suburbanTechInspection && (
        <ServiceItem
          icon="inspection"
          href={links.suburbanTechInspection}
          onClick={() => onServiceClick('application_ts')}
        >
          Проверка качества строительства дома
        </ServiceItem>
      )}
      {links.businessAnalytics && (
        <ServiceItem icon="businessAnalytics" href={links.businessAnalytics}>
          Аналитика бизнесу
        </ServiceItem>
      )}
    </ul>
  );
};
