import { TDealsState } from '../../types/deals';

const defaultState: TDealsState = {
  isE2eDealVisible: false,
  count: 0,
};

export function dealsReducer(state: TDealsState = defaultState): TDealsState {
  return state;
}
