import { useSelector } from 'react-redux';

import { selectIsRealtor } from '../../selectors/user';
import { useApplicationContext } from '../applicationContext';

/**
 * Возвращает флаг, доступны ли пользователю ссылки на сервис "Ипотека для профи".
 */
export const useIsMortgageBrokerLinksAvailable = (): boolean => {
  const { config } = useApplicationContext();
  const isRealtor = useSelector(selectIsRealtor);
  const isEnabled = config.get<boolean>('header-frontend.mortgageBrokerLinks.Enabled');

  return Boolean(isRealtor && isEnabled);
};
