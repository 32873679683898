import * as React from 'react';
import { useSelector } from 'react-redux';

import { Utilities } from '../../../components/Utilities';
import { selectIsAuthenticated } from '../../../selectors/user';
import { IApplicationState } from '../../../types/redux';
import { UtilityCompareContainer } from '../../UtilityCompareContainer';
import { UtilityFavoritesContainer } from '../../UtilityFavoritesContainer/Redesign';
import { UtilityMessagesContainer } from '../../UtilityMessagesContainer';
import { UtilityNotificationsContainer } from '../../UtilityNotificationsContainer';
import { UtilitySavedSearchesContainer } from '../../UtilitySavedSearchesContainer';

export const UtilitiesContainer: React.FC = () => {
  const isAuthenticated = useSelector<IApplicationState, boolean>(selectIsAuthenticated);

  return (
    <Utilities testId="UtilitiesRedesign">
      <UtilityCompareContainer />
      <UtilityMessagesContainer />
      <UtilityFavoritesContainer />
      {isAuthenticated && <UtilitySavedSearchesContainer />}
      <UtilityNotificationsContainer />
    </Utilities>
  );
};
