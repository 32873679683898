import { ca } from '@cian/analytics';

export function trackingMobileAppClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'top_menu',
    label: 'cian_app',
  });
}
