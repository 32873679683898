import { useDeviceType } from '@cian/ui-kit/responsive';
import { IconActionNotificationBadge16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackNotificationIconClick } from './tracking';
import { modalOpen } from '../../actions/authentication';
import { openNotificationsModal, openNotificationsModalAfterAuth } from '../../actions/notifications/openModal';
import { Utility } from '../../components/Utility';
import { selectIsAuthorizationTrapExperimentEnabled } from '../../selectors/experiments/selectIsAuthorizationTrapExperimentEnabled';
import { selectIsAuthenticated } from '../../selectors/user';
import { IApplicationState } from '../../types/redux';
import { NotificationCenterContainer } from '../NotificationCenterContainer';

export const UtilityNotificationsContainer: React.FC = () => {
  const deviceType = useDeviceType();
  const dispatch = useDispatch();

  const notificationsLink = useSelector<IApplicationState, string | undefined>(
    state => state.servicesLinks.notifications || undefined,
  );
  const notificationsCount = useSelector<IApplicationState, number>(state => state.notifications.count);
  const isOpen = useSelector<IApplicationState, boolean>(state => state.notifications.isNotificationsModalOpen);

  const isAuthenticated = useSelector<IApplicationState, boolean>(selectIsAuthenticated);
  const isAutTrapExperimentEnabled = useSelector<IApplicationState, boolean>(
    selectIsAuthorizationTrapExperimentEnabled,
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (deviceType === 'desktop') {
        event.preventDefault();

        if (isAutTrapExperimentEnabled && !isAuthenticated) {
          dispatch(openNotificationsModalAfterAuth());
          dispatch(
            modalOpen({
              autoRefresh: false,
              alert: null,
              source: 'notifications',
              viewData: { headingType: 'notifications' },
            }),
          );
        } else {
          dispatch(openNotificationsModal(true));
        }
      }

      trackNotificationIconClick();
    },
    [deviceType, isAuthenticated, isAutTrapExperimentEnabled, dispatch],
  );

  const handleClose = React.useCallback(() => {
    dispatch(openNotificationsModal(false));
  }, [dispatch]);

  if (!notificationsLink && deviceType !== 'desktop') {
    return null;
  }

  return (
    <>
      <Utility
        data-name="UtilityNotificationsContainer"
        title="Уведомления"
        unread={Boolean(notificationsCount)}
        icon={IconActionNotificationBadge16}
        href={notificationsLink}
        onClick={handleClick}
      />
      {isOpen && <NotificationCenterContainer onClose={handleClose} />}
    </>
  );
};
