import * as React from 'react';
import { useSelector } from 'react-redux';

import { DropdownBackdrop } from '../../components/Dropdown';
import { IApplicationState } from '../../types/redux';

export const DropdownBackdropContainer: React.FC = () => {
  const open = useSelector((state: IApplicationState) => state.dropdown.open);

  if (!open) {
    return null;
  }

  return <DropdownBackdrop />;
};
