import { ErrorLogComponent } from '@cian/error-log-component';
import loadable from '@loadable/component';
import * as React from 'react';

import { useCatProfilingModalProps } from './useCatProfilingModalProps';
import { useApplicationContext } from '../../utils/applicationContext';

const CatProfilingComponent = loadable.lib(() => import('@cian/frontend-cat-profiling-component'));

export function CatProfilingModalContainer() {
  const ctx = useApplicationContext();
  const modalProps = useCatProfilingModalProps();

  if (!modalProps) {
    return null;
  }

  return (
    <ErrorLogComponent logger={ctx.logger}>
      <CatProfilingComponent>{({ ProfilingModal }) => <ProfilingModal {...modalProps} />}</CatProfilingComponent>
    </ErrorLogComponent>
  );
}
