import { ResponseError } from '../../errors';
import {
  fetchIsRealtyChangesExists,
  TIsRealtyChangesExistsResponse,
} from '../../repositories/price-estimator/v1/is-realty-changes-exists';
import { IApplicationContext } from '../../types/applicationContext';

export async function getMyHomeIsExists(context: IApplicationContext) {
  const { httpApi, logger } = context;

  try {
    const res: TIsRealtyChangesExistsResponse = await fetchIsRealtyChangesExists({ httpApi });

    if (res.statusCode !== 200) {
      throw new ResponseError({
        domain: 'getMyHomeIsExists',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        message: res.response.message!,
        details: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: res.response.errors as any,
        },
      });
    }

    return res.response.exists || false;
  } catch (error) {
    logger.warning(error, {
      domain: 'getMyHomeIsExists',
      message: 'Failed get my home count',
    });

    return false;
  }
}
