import { Responsive } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { HeaderLayout } from '../../../components/Header/Redesign';
import { useEventBus } from '../../../hooks/useEventBus';
import { IApplicationState } from '../../../types/redux';
import { AuthenticationModalContainer } from '../../AuthenticationModalContainer';
import { DropdownContainer } from '../../DropdownContainer';
import { DropdownBackdropContainer } from '../../DropdownContainer/DropdownBackdropContainer';
import { HamburgerContainer } from '../../HamburgerContainer';
import { LogoContainer } from '../../LogoContainer';
import { NavBarContainer } from '../../NavBarContainer/Redesign';
import { UserRelatedContainer } from '../../UserRelatedContainer';

export const HeaderContainer: React.FC = () => {
  useEventBus();

  const noResize = useSelector((state: IApplicationState) => state.noResize);

  if (noResize) {
    return (
      <>
        <HeaderLayout
          noResize={noResize}
          content={
            <>
              <LogoContainer />
              <UserRelatedContainer />
              <AuthenticationModalContainer />
            </>
          }
          navbar={<NavBarContainer />}
        >
          <DropdownContainer />
        </HeaderLayout>
        <DropdownBackdropContainer />
      </>
    );
  }

  return (
    <>
      <HeaderLayout
        noResize={noResize}
        content={
          <>
            <Responsive phone tablet>
              <HamburgerContainer />
            </Responsive>
            <LogoContainer />
            <UserRelatedContainer />
            <AuthenticationModalContainer />
          </>
        }
        navbar={
          <Responsive desktop>
            <NavBarContainer />
          </Responsive>
        }
      >
        <DropdownContainer />
      </HeaderLayout>
      <DropdownBackdropContainer />
    </>
  );
};
