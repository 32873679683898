import { EMessagesActionTypes } from '../../actions/messages';
import { IMessagesState } from '../../types/messages';
import { TReduxActions } from '../../types/redux';

const defaultState: IMessagesState = {
  count: 0,
};

export function messagesReducer(state: IMessagesState = defaultState, action: TReduxActions): IMessagesState {
  switch (action.type) {
    case EMessagesActionTypes.UpdateCount:
      return {
        count: action.payload,
      };
    default:
      return state;
  }
}
