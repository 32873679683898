import { ENotificationsActionTypes } from './types';
/**
 * @todo пофиксить импорт https://jira.cian.tech/browse/CD-155930
 */
// eslint-disable-next-line import/no-restricted-paths
import { subscribe } from '../../../browser/services/webPush';
import { EWebPushStatus } from '../../types/notifications';
import { TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export const setWebPushStatus = actionGenerator<ENotificationsActionTypes.SetWebPushStatus, EWebPushStatus>(
  ENotificationsActionTypes.SetWebPushStatus,
);

export const subscribeToWebPush =
  (): TThunkAction<Promise<void>> =>
  async (dispatch, getState, { logger }) => {
    const {
      notifications: { webPushStatus },
    } = getState();

    if (webPushStatus === EWebPushStatus.Subscribing) {
      return;
    }

    dispatch(setWebPushStatus(EWebPushStatus.Subscribing));

    try {
      await subscribe();
      dispatch(setWebPushStatus(EWebPushStatus.Subscribed));
    } catch (e) {
      dispatch(setWebPushStatus(EWebPushStatus.Unsubscribed));
      logger.warning(e, {
        domain: 'actions.webPushSubscription.subscribe',
        message: 'Failed to subscribe',
      });
    }
  };
