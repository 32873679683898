import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { Application } from '../../../shared/containers/App';
import { IApplicationContext } from '../../../shared/types/applicationContext';
import { TReduxStore } from '../../../shared/types/redux';

interface IDependencies {
  store: TReduxStore;
  context: IApplicationContext;
}

export function renderApplication(dependencies: IDependencies) {
  const { store, context } = dependencies;
  const { config } = context;

  const projectName = config.getStrict<string>('projectName');
  const rootElement = document.getElementById(projectName as string);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  hydrateRoot(rootElement, <Application context={context} reduxStore={store} />);
}
