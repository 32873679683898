import { EAuthenticationActionTypes, TModalClose } from './types';
/**
 * @todo пофиксить импорт https://jira.cian.tech/browse/CD-155930
 */
// eslint-disable-next-line import/no-restricted-paths
import { getEvents } from '../../../browser/services/events';

export const modalClose = (isAuthenticated?: boolean): TModalClose => {
  if (!isAuthenticated) {
    getEvents().authModalClosedNoAuth();
  }

  return {
    type: EAuthenticationActionTypes.ModalClose,
    payload: undefined,
  };
};
