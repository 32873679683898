import { IGeoForDesktopSchema } from '../../../repositories/favorites/entities/favorite_entity_for_desktop/GeoForDesktopSchema';

export const getAddressString = (address: IGeoForDesktopSchema['address']): string | null => {
  const addressArr = address.map(({ title }) => title);

  if (!addressArr.length) {
    return null;
  }

  return addressArr.join(', ');
};
