import { getEventBusIfDefined } from '@cian/event-bus';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { setRegionId } from '../../actions/geo';

/**
 * некоторые мкс используют отправку событий в header через старый канал @cian/event-bus
 * пока поддерживаем для обратной совместимости
 * @deprecated
 * @todo переделать отправку событий на @cian/events-log в frontend-serp, и т.д
 */
export function useEventBus() {
  const mounted = useRef(false);
  const dispatch = useDispatch();

  const updateRegion = useCallback(
    (event: CianEventBus.IEvent) => {
      const regionId = parseInt(event.type, 10);

      if (!regionId || isNaN(regionId)) {
        return;
      }

      dispatch(setRegionId(regionId));
    },
    [dispatch],
  );

  useEffect(() => {
    const eventBus = getEventBusIfDefined();
    if (eventBus && !mounted.current) {
      eventBus.subscribe('header:region_changed', updateRegion);
      mounted.current = true;
    }
  }, [updateRegion]);
}
