import { IconActionBurgerMenu24 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './Hamburger.css';

interface IHamburgerProps {
  onMouseDown(): void;
  onClick(): void;
}

export const Hamburger: React.FC<IHamburgerProps> = ({ onMouseDown, onClick }) => {
  const handleMouseDown = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      onMouseDown();
    },
    [onMouseDown],
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      onClick();
    },
    [onClick],
  );

  return (
    <div className={styles['container']}>
      <button className={styles['button']} onMouseDown={handleMouseDown} onClick={handleClick}>
        <IconActionBurgerMenu24 display="block" color="current_color" />
      </button>
    </div>
  );
};
