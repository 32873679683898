import { parseCookies } from '@cian/utils';
import { deleteCookie } from '@cian/utils/lib/browser/cookie';
import { useEffect, useMemo } from 'react';

import { useApplicationContext } from '../utils/applicationContext';

const SKIP_HEADER_BANNERS_COOKIE_CONFIG_KEY = 'skipHeaderBannersCookieName';

/**
 * Возвращает, установлена ли извне шапки кука, указывающая на пропуск баннеров.
 */
export const useIsBannerSkippedByCookie = (): boolean => {
  const { config } = useApplicationContext();

  const cookieName = config.get<string>(SKIP_HEADER_BANNERS_COOKIE_CONFIG_KEY);

  const isSkippedByCookie = useMemo(() => {
    if (typeof document === 'undefined' || !cookieName) {
      return false;
    }

    return parseCookies(document.cookie)[cookieName] === '1';
  }, [cookieName]);

  useEffect(() => {
    if (isSkippedByCookie) {
      deleteCookie(cookieName as string);
    }
  }, [cookieName, isSkippedByCookie]);

  return isSkippedByCookie;
};
