import { generateOneLinkUrl } from '@cian/appsflyer-onelink';
import { type IPinnedButtonProps } from '@cian/mobile-banner-component';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MOBILE_BANNER_TITLE } from './constants';
import { trackClickOnBanner } from './tracking';
import { useAllowedPinnedButtonPage } from '../../hooks/useAllowedPinnedButtonPage';
import { usePinnedButtonEntryPoint } from '../../hooks/usePinnedButtonEntryPoint';
import { EMobileDeviceType } from '../../types/mobileDeviceType';
import { useApplicationContext } from '../../utils/applicationContext';

export function usePinnedButtonProps(): IPinnedButtonProps | null {
  const { config, custom } = useApplicationContext();
  const headerEntryPointEnabled = usePinnedButtonEntryPoint();
  const isAllowedPage = useAllowedPinnedButtonPage();

  const isPinnedButtonEnabled = config.get<boolean>('BX.PinnedButton.Enabled') ?? false;

  const [url, setUrl] = useState<string | null>(config.get<string>('mobileBanner.browserUrl') || null);

  useEffect(() => {
    setUrl(generateOneLinkUrl(url, 'mobile_site', MOBILE_BANNER_TITLE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBannerClick = useCallback(() => {
    trackClickOnBanner(custom.mobileDeviceType);
  }, [custom.mobileDeviceType]);

  return useMemo(() => {
    if (
      !isPinnedButtonEnabled ||
      !url ||
      !headerEntryPointEnabled ||
      custom.mobileDeviceType === EMobileDeviceType.Unknown ||
      !isAllowedPage
    ) {
      return null;
    }

    return {
      url,
      caClickOnBanner: handleBannerClick,
    };
  }, [custom.mobileDeviceType, handleBannerClick, headerEntryPointEnabled, isAllowedPage, isPinnedButtonEnabled, url]);
}
