import { IEvent, createConsumer, createSubscriber, getGlobalEventsLogs } from '@cian/events-log';

import { EAlertType } from '../../../shared/types/authentication';
import { IAuthenticateEvent } from '../events/types';

const TOPIC_USER = 'user';
const TOPIC_HEADER = 'header';

export function initLegacyCallbacks() {
  const globalLog = getGlobalEventsLogs();

  window.waitForLoginPopup = (alert?: EAlertType, onSuccess?: () => void) => {
    if (onSuccess) {
      const consumer = createConsumer({ topic: TOPIC_USER });
      const userTopicHandler = (event: IEvent<unknown>) => {
        if (event.type === 'authenticated') {
          onSuccess();
          consumer.destroy();
        }
      };

      const userSubscriber = createSubscriber(userTopicHandler);
      consumer.subscribe(userSubscriber);
    }

    globalLog.produce<Required<IAuthenticateEvent>>(TOPIC_USER, {
      type: 'authenticate',
      value: {
        autoRefresh: !onSuccess,
        alert: alert ?? null,
        useDefaultView: false,
        viewData: null,
        source: null,
      },
    });
  };

  window.refreshAfterUserLoggedIn = () => {
    globalLog.produce(TOPIC_HEADER, {
      type: 'update',
      value: null,
    });
  };

  window.__reloadHeader__ = () => {
    globalLog.produce(TOPIC_HEADER, {
      type: 'update',
      value: null,
    });
  };
}
