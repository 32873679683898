import { ca } from '@cian/analytics';

import { TPageType } from '../../types/page';
import { pageTypeToAnalyticsCategory } from '../../utils/pageType';

export function trackSpecialPromoClick(pageType: TPageType, promoKey: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: pageTypeToAnalyticsCategory(pageType),
    action: 'top_menu',
    label: promoKey,
  });
}
