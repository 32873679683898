import * as React from 'react';

import * as styles from './Login.css';
import iconLogin from './assets/login.svg';

interface ILoginProps {
  href: string;
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
}

export const Login: React.FC<ILoginProps> = ({ href, onClick }) => {
  return (
    <a href={href} className={styles['container']} onClick={onClick}>
      <div className={styles['icon']} style={{ backgroundImage: `url("${iconLogin}")` }} />
      Войти
    </a>
  );
};
