import { TNotification } from '../../../types/notifications';
import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EGetNotificationsListActionTypes {
  Initial = 'getNotificationsList/initial',
  Loading = 'getNotificationsList/loading',
  Succeed = 'getNotificationsList/succeed',
  Failed = 'getNotificationsList/failed',
}

export type TGetNotificationsListInitial = ITypedReduxAction<EGetNotificationsListActionTypes.Initial>;
export type TGetNotificationsListLoading = ITypedReduxAction<EGetNotificationsListActionTypes.Loading>;
export type TGetNotificationsListSucceed = ITypedReduxAction<EGetNotificationsListActionTypes.Succeed, TNotification[]>;
export type TGetNotificationsListFailed = ITypedReduxAction<EGetNotificationsListActionTypes.Failed>;

export type TGetNotificationsList =
  | TGetNotificationsListInitial
  | TGetNotificationsListLoading
  | TGetNotificationsListSucceed
  | TGetNotificationsListFailed;
