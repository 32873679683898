import { ca } from '@cian/analytics';

export function trackingMyHomeIconClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'top_menu',
    label: 'my_home',
  });
}
