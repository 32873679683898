import { EDropdownActionType } from './types';
import { TThunkAction } from '../../types/redux';
import { ERubric } from '../../types/rubricsLinks';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const TOGGLE_DROPDOWN_DELAY = 200;

export const openDropdown = actionGenerator<EDropdownActionType.Open>(EDropdownActionType.Open);

export const closeDropdown = actionGenerator<EDropdownActionType.Close>(EDropdownActionType.Close);

export const setHoveredRubric = actionGenerator<EDropdownActionType.SetHoveredRubric, ERubric | null>(
  EDropdownActionType.SetHoveredRubric,
);

export const setCloseDropdownTimeout = actionGenerator<EDropdownActionType.SetActiveCloseTimeout, number | null>(
  EDropdownActionType.SetActiveCloseTimeout,
);

export const setOpenDropdownTimeout = actionGenerator<EDropdownActionType.SetActiveOpenTimeout, number | null>(
  EDropdownActionType.SetActiveOpenTimeout,
);

export const debouncedOpenRubric =
  (rubric: ERubric): TThunkAction =>
  (dispatch, getState) => {
    const { activeCloseTimeout, activeOpenTimeout } = getState().dropdown;
    if (activeCloseTimeout !== null) {
      window.clearTimeout(activeCloseTimeout);
      dispatch(setCloseDropdownTimeout(null));
    }
    if (activeOpenTimeout !== null) {
      window.clearTimeout(activeOpenTimeout);
    }
    const timeout = window.setTimeout(() => {
      dispatch(openDropdown());
      dispatch(setHoveredRubric(rubric));
    }, TOGGLE_DROPDOWN_DELAY);

    dispatch(setCloseDropdownTimeout(timeout));
  };

export const debouncedCloseRubric = (): TThunkAction => (dispatch, getState) => {
  const { activeCloseTimeout, activeOpenTimeout } = getState().dropdown;
  if (activeOpenTimeout !== null) {
    window.clearTimeout(activeOpenTimeout);
    dispatch(setOpenDropdownTimeout(null));
  }

  if (activeCloseTimeout !== null) {
    window.clearTimeout(activeCloseTimeout);
  }
  const timeout = window.setTimeout(() => {
    dispatch(closeDropdown());
    dispatch(setHoveredRubric(null));
  }, TOGGLE_DROPDOWN_DELAY);

  dispatch(setCloseDropdownTimeout(timeout));
};

export const clearDebouncedCloseRubric = (): TThunkAction => (dispatch, getState) => {
  const { activeCloseTimeout } = getState().dropdown;
  if (activeCloseTimeout !== null) {
    window.clearTimeout(activeCloseTimeout);
  }
  dispatch(setCloseDropdownTimeout(null));
};
