import * as Cookies from 'js-cookie';

const LOGIN_MRO_COOKIE = 'login_mro_popup';

export function isMROAvailable() {
  return !Cookies.get(LOGIN_MRO_COOKIE);
}

export function invalidateMROCookie() {
  Cookies.set(LOGIN_MRO_COOKIE, '1', {
    domain: '.cian.ru',
  });
}
