import { ca } from '@cian/analytics';

export function trackChatsIconClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'top_menu',
    label: 'messages',
  });
}
