import { useSelector } from 'react-redux';

import { selectNavbarRubrics, selectRubricsLinks } from '../../../selectors/rubricLinks';
import { INavbarLink } from '../../../types/navbar';
import { ERubric } from '../../../types/rubricsLinks';
import { useIsMortgageBrokerLinksAvailable } from '../../../utils/useIsMortgageBrokerLinksAvailable';

export const useGetRubrics = (): INavbarLink[] => {
  let navbarRubrics = useSelector(selectNavbarRubrics);
  const rubricLinks = useSelector(selectRubricsLinks);
  const isMortgageBrokerAvailable = useIsMortgageBrokerLinksAvailable();

  if (isMortgageBrokerAvailable) {
    navbarRubrics = navbarRubrics.flatMap(item => {
      return item.rubric === ERubric.Mortgage
        ? [
            {
              ...item,
              url: `${rubricLinks.mortgageBrokerPromo}?cian_source=header_mortgage`,
            },
          ]
        : [item];
    });
  }

  return navbarRubrics;
};
