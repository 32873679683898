import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './LoadableError.css';
import { ILoadableErrorProps } from '../../types/loadableError';

export function LoadableError({ text, onRetry }: ILoadableErrorProps) {
  return (
    <div data-testid="loadable-error" className={styles['container']}>
      <p className={styles['text']}>
        {text ||
          'Во время загрузки произошла ошибка. Пожалуйста, проверьте своё интернет соединение и попробуйте ещё раз.'}
      </p>
      <Button onClick={onRetry}>Повторить</Button>
    </div>
  );
}
