import {
  fetchDesktopGetNotificationsCount,
  TDesktopGetNotificationsCountResponse,
} from '../../repositories/notification-center/v1/desktop/get-notifications-count';
import { IApplicationContext } from '../../types/applicationContext';

const DEGRADATION_TELEMETRY_KEY = 'degradation.notifications-count';

export async function getNotificationsCount(context: IApplicationContext) {
  const { httpApi, logger, telemetry } = context;

  try {
    const response: TDesktopGetNotificationsCountResponse = await fetchDesktopGetNotificationsCount({
      httpApi,
    });

    if (response.statusCode === 200) {
      return Number(response.response.hasUnreadNotifications);
    }

    telemetry.increment(DEGRADATION_TELEMETRY_KEY);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    logger.warning(response.response.message!);

    return 0;
  } catch (error) {
    telemetry.increment(DEGRADATION_TELEMETRY_KEY);
    logger.warning(error, {
      domain: 'getNotificationsCount',
      message: 'Failed get notifications count',
    });

    return 0;
  }
}
