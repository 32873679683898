import { ca } from '@cian/analytics';

export function trackingCompareIconClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Compare',
    action: 'click',
    label: 'to_compare',
  });
}
