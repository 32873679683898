import { UIHeading5, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IFavoritesItemProps {
  title: string;
  offerUrl: string;
  price: string | null;
  imgUrl: string | null;
  address: string | null;
  metro: React.ReactNode | null;
  onCLick: () => void;
}

export const FavoritesItem: React.FC<IFavoritesItemProps> = ({
  title,
  offerUrl,
  price,
  address,
  imgUrl,
  metro,
  onCLick,
}) => {
  return (
    <a
      className={styles['item']}
      href={offerUrl}
      target="_blank"
      rel="noreferrer"
      onClick={onCLick}
      data-name="FavoritesItem"
    >
      <div className={styles['title']}>
        <UIHeading5>{title}</UIHeading5>
        {price && <UIText2>{price}</UIText2>}

        {metro}
        {address && !metro && <UIText2>{address}</UIText2>}
      </div>

      {imgUrl && <div className={styles['preview']} style={{ backgroundImage: `url(${imgUrl})` }} />}
    </a>
  );
};
