import { useDeviceType } from '@cian/ui-kit';
import { useMemo } from 'react';

import { useApplicationContext } from '../utils/applicationContext';

export function useNewMobileAppBannerEntryPoint(): boolean {
  const context = useApplicationContext();
  const deviceType = useDeviceType();
  const isEnabled = context.config.get<boolean>('BX.BannerHeaderEntry.Enabled') ?? false;

  return useMemo(
    () => isEnabled && deviceType === 'phone',
    // Вычисляем однократно, чтобы баннер не появлялся при ресайзе
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
