import { ResponseError } from '../../errors';
import {
  fetchGetTotalUnreadMessagesCount,
  TGetTotalUnreadMessagesCountResponse,
} from '../../repositories/chats/v1/get-total-unread-messages-count';
import { IApplicationContext } from '../../types/applicationContext';

export async function getUnreadMessages(context: IApplicationContext, isAuthenticated: boolean): Promise<number> {
  if (!isAuthenticated) {
    return 0;
  }

  const { httpApi, logger } = context;

  try {
    const messages: TGetTotalUnreadMessagesCountResponse = await fetchGetTotalUnreadMessagesCount({
      httpApi,
    });

    if (messages.statusCode !== 200) {
      throw new ResponseError({
        domain: 'getUnreadMessages',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        message: messages.response.message!,
        details: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: messages.response.errors as any,
        },
      });
    }

    return messages.response.unread;
  } catch (ex) {
    logger.warning(ex, {
      domain: 'getUnreadMessages',
      message: 'Failed to get unread messages',
    });

    return 0;
  }
}
