import { useSelector } from 'react-redux';

import { selectIsRealtor } from '../../../selectors/user';
import { IApplicationState } from '../../../types/redux';
import { IServicesLinksState } from '../../../types/servicesLinks';

export const useMyHomeProps = () => {
  const isRealtor = useSelector(selectIsRealtor);
  const servicesLinks = useSelector<IApplicationState, IServicesLinksState>(state => state.servicesLinks);

  const serviceName = isRealtor ? 'Онлайн оценка квартир' : 'Оценка и Мой дом';
  const serviceLabel = isRealtor ? 'EstateCalculator' : 'my_home';
  const serviceUrl = isRealtor ? servicesLinks.flatValuation : servicesLinks.myHome;

  return {
    valuationServiceName: serviceName,
    valuationServiceLabel: serviceLabel,
    valuationServiceUrl: serviceUrl,
  };
};
