import { ca } from '@cian/analytics';

import { MOBILE_BANNER_TITLE } from './constants';
import { EMobileDeviceType } from '../../types/mobileDeviceType';

export const trackShowBanner = (platform: EMobileDeviceType) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'show',
    category: 'Mob_smart_banner',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
    page: {
      extra: {
        bannerName: MOBILE_BANNER_TITLE,
      },
    },
  });
};

export const trackClickOnBanner = (platform: EMobileDeviceType) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'click',
    category: 'Mob_smart_banner',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
    page: {
      extra: {
        bannerName: MOBILE_BANNER_TITLE,
      },
    },
  });
};

export const trackOnCloseBanner = (platform: EMobileDeviceType) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'close',
    category: 'Mob_smart_banner',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
    page: {
      extra: {
        bannerName: MOBILE_BANNER_TITLE,
      },
    },
  });
};
