import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EMarkAsReadNotificationActionTypes {
  Initial = 'markAsReadNotification/initial',
  Loading = 'markAsReadNotification/loading',
  Succeed = 'markAsReadNotification/succeed',
  Failed = 'markAsReadNotification/failed',
}

export type TMarkAsReadNotificationInitial = ITypedReduxAction<EMarkAsReadNotificationActionTypes.Initial>;
export type TMarkAsReadNotificationLoading = ITypedReduxAction<EMarkAsReadNotificationActionTypes.Loading>;
export type TMarkAsReadNotificationSucceed = ITypedReduxAction<EMarkAsReadNotificationActionTypes.Succeed, number>;
export type TMarkAsReadNotificationFailed = ITypedReduxAction<EMarkAsReadNotificationActionTypes.Failed>;

export type TMarkAsReadNotification =
  | TMarkAsReadNotificationInitial
  | TMarkAsReadNotificationLoading
  | TMarkAsReadNotificationSucceed
  | TMarkAsReadNotificationFailed;
