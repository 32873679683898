import loadable from '@loadable/component';
import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { trackClickQualityOfService, trackLogoutClick } from './tracking';
import { logoutUser } from '../../actions/logout';
import { LoadableErrorBoundary } from '../../components/LoadableErrorBoundary';
import { IUserMenuProps } from '../../components/UserMenu';
import { selectQualityLevelInfo } from '../../selectors/qualityLevelInfo';
import { selectSuburbanBuildersAccountsList } from '../../selectors/suburbanBuilders';
import { selectUser } from '../../selectors/user';
import { TQualityLevelInfo } from '../../types/qualityLevelInfo';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { IServicesLinksState } from '../../types/servicesLinks';
import { IUserAuthenticatedState } from '../../types/user';
import { IUserAreaState } from '../../types/userArea';
import { useApplicationContext } from '../../utils/applicationContext';

type TUserMenuContainerProps = Pick<IUserMenuProps, 'onClose'>;

const UserMenuInnerLoadable = loadable(() => import(/* webpackChunkName: "UserMenuInner" */ './loadable'), {
  ssr: false,
});

export const UserMenuContainer: React.FC<TUserMenuContainerProps> = ({ onClose }) => {
  const { logger } = useApplicationContext();
  const user = useSelector(selectUser) as IUserAuthenticatedState;
  const userArea = useSelector<IApplicationState, IUserAreaState>(state => state.userArea);
  const servicesLinks = useSelector<IApplicationState, IServicesLinksState>(state => state.servicesLinks);
  const leadsCount = useSelector<IApplicationState, number>(state => state.leads.count);
  const activeDealsCount = useSelector<IApplicationState, number>(state => state.deals.count);
  const qualityLevelInfo = useSelector<IApplicationState, TQualityLevelInfo | null>(
    selectQualityLevelInfo,
    shallowEqual,
  );
  const suburbanBuildersAccountsList = useSelector(selectSuburbanBuildersAccountsList);

  const dispatch = useDispatch<TThunkDispatch>();

  const isSuburbanBuilder = React.useMemo(
    () => !!user.userId && !!suburbanBuildersAccountsList[user.userId],
    [suburbanBuildersAccountsList, user],
  );

  const handleLogout = React.useCallback(() => {
    dispatch(logoutUser());
    trackLogoutClick();
    onClose();
  }, [dispatch, onClose]);

  const handleRetry = React.useCallback(() => {
    UserMenuInnerLoadable.load();
  }, []);

  const handleClickQualityOfService = React.useCallback(() => {
    trackClickQualityOfService(qualityLevelInfo?.analyticsKey);
  }, []);

  return (
    <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
      <UserMenuInnerLoadable
        leadsCount={leadsCount}
        activeDealsCount={activeDealsCount}
        user={user}
        userArea={userArea}
        servicesLinks={servicesLinks}
        onClose={onClose}
        logout={handleLogout}
        isSuburbanBuilder={isSuburbanBuilder}
        qualityLevelInfo={qualityLevelInfo}
        onClickQualityOfService={handleClickQualityOfService}
      />
    </LoadableErrorBoundary>
  );
};
