import { TPageType } from '../../types/page';

export function pageTypeToAnalyticsCategory(pageType: TPageType): string {
  switch (pageType) {
    case 'main':
      return 'main_page';
    case 'mainRent':
      return 'main_rent';
    case 'mainRentDaily':
      return 'main_rent';
    case 'mainSale':
      return 'main_sale';
    case 'mainCommercial':
      return 'main_commercial';
    case 'mainServices':
      return 'main_services';
    case 'mainResidentialComplexes':
      return 'main_newobjects';
    case 'mainCountryside':
      return 'main_countryside';
    default:
      return 'other_page';
  }
}
