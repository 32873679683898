import { IHeaderData } from '../../headerData';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EHeaderStateActionType {
  Update = 'header-state/update',
}

export type TUpdateHeaderState = ITypedReduxAction<EHeaderStateActionType.Update, IHeaderData>;

export type THeaderStateActions = TUpdateHeaderState;
