import { ITypedReduxAction } from '../../types/redux/actionType';

export enum ELogoutActionTypes {
  Initial = 'logout/initial',
  Loading = 'logout/loading',
  Succeed = 'logout/succeed',
  Failed = 'logout/failed',
}

export type TLogoutInitial = ITypedReduxAction<ELogoutActionTypes.Initial>;
export type TLogoutLoading = ITypedReduxAction<ELogoutActionTypes.Loading>;
export type TLogoutSucceed = ITypedReduxAction<ELogoutActionTypes.Succeed>;
export type TLogoutFailed = ITypedReduxAction<ELogoutActionTypes.Failed>;

export type TLogoutActions = TLogoutInitial | TLogoutLoading | TLogoutSucceed | TLogoutFailed;
