import { IDeveloperBanner } from '../../types/developerBanners';

/**
 * При планировании решили, что бек будет присылать массив данных,
 * А на фронте будем брать рандомный элемент и его отображать.
 * Решили присылать все данные с бэка, а не сразу 1 элемент, т.к. бизнес возможно захочет сделать ротацию в баннере
 * И тогда понадобится весь массив данных.
 */
export function prepareDeveloperBanner(banners: IDeveloperBanner[]): IDeveloperBanner | null {
  const bannersLength = banners.length;

  if (!bannersLength) {
    return null;
  }

  // Случайный элемент от 0 до bannersLength - 1
  const randomIndex = (Math.random() * bannersLength) | 0;

  return banners[randomIndex];
}
