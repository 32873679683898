import loadable from '@loadable/component';
import * as React from 'react';

import { LoadableFallback } from '../LoadableFallback';

export const FeedbackFormTechProblemsLoadable = loadable(
  () => import(/* webpackChunkName: "FeedbackFormTechProblems" */ './FeedbackFormTechProblems'),
  {
    fallback: <LoadableFallback />,
    ssr: false,
  },
);
