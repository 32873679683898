import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackingMyHomeIconClick } from './tracking';
import { MyHomeLinkLayout } from '../../components/MyHomeLink';
import { selectIsRealtor } from '../../selectors/user';

export const MyHomeDesktopContainer: React.FC = () => {
  const isRealtor = useSelector(selectIsRealtor);

  const handleClick = React.useCallback(() => {
    trackingMyHomeIconClick();
  }, []);

  if (isRealtor) {
    return null;
  }

  return (
    <MyHomeLinkLayout onClick={handleClick}>
      <span>Мой дом</span>
    </MyHomeLinkLayout>
  );
};
