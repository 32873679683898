import * as React from 'react';

import * as styles from './styles.css';
import { EMyHomeFrom } from '../../containers/MyHomeDesktopContainer/utils/constants';

interface IMyHomeLinkLayoutProps extends React.PropsWithChildren {
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
}

export const MyHomeLinkLayout: React.FC<IMyHomeLinkLayoutProps> = ({ onClick, children }) => {
  return (
    <a className={styles['link']} href={`https://www.cian.ru/my-home/${EMyHomeFrom.fromMain}`} onClick={onClick}>
      {children}
    </a>
  );
};
