import { ErrorLogComponent } from '@cian/error-log-component';
import loadable from '@loadable/component';
import * as React from 'react';

import { useIsOnboardingEnabled } from './useIsOnboardingEnabled';
import { useApplicationContext } from '../../utils/applicationContext';

const OnboardingContainerLoadable = loadable(() => import('./OnboardingContainerLoadable'), {
  resolveComponent: module => module.OnboardingContainer,
});

export function OnboardingContainer() {
  const ctx = useApplicationContext();
  const isEnabled = useIsOnboardingEnabled();

  if (!isEnabled) {
    return null;
  }

  return (
    <ErrorLogComponent logger={ctx.logger}>
      <OnboardingContainerLoadable />
    </ErrorLogComponent>
  );
}
