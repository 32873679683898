// дублируем EActiveView из виджета авторизации, импорт enum напрямую из виджета потянет за собой большой бандл
export enum EActiveView {
  PhoneAuth = 'phoneAuth', // Авторизации/регистрации по телефону. Здесь вводится номер телефона пользователя.
  PhoneAuthValidateSms = 'phoneAuthValidateSms', // Экран проверки номера телефона по смс. Здесь пользователь вводит из смс код, чтобы подтвердить свой телефонный номер.
  PhoneAuthValidateCall = 'phoneAuthValidateCall', // Экран проверки номера телефона по звонку. Здесь на указанный номер пользователя поступает телефонный звонок, а он вводит последние 4 цифры этого телефонного номера.
  AccountSelect = 'accountSelect', // Экран выбора аккаунта. Здесь пользователь должен выбрать, хочет ли он авторизоваться по email, к которому уже привязан данный номер телефона, либо может "продолжить по телефону".
  ConfirmSelectedAccount = 'confirmSelectedAccount',
  AccountSelectError = 'accountSelectError', // Экран ошибки при выборе аккаунта
  TokenExpiredError = 'tokenExpiredError',
  AuthenticationRegistration = 'authenticationRegistration', // Экран авторизации/регистрации по email или id. Здесь пользователь вводит email или id.
  Agreement = 'agreement', // Экран принятия пользовательского соглашения. Здесь пользователь ставит галочки о том, что он ознакомлен с пользовательским соглашением и принимает/отклоняет маркетинговую рассылку.
  SocialCreate = 'socialCreate',
  SocialMerge = 'socialMerge',
  TwoFactorWithPhone = 'twoFactorWithPhone', // Экран ДФА, проверка по телефонному номеру. Пользователь вводит свой номер телефона, проверочный код отправляется по смс, либо по звонку.
  TwoFactorWithEmail = 'twoFactorWithEmail', // Экран ДФА, проверка по email. Пользователь заходит с незнакомого ip адреса, проверочный код высылается письмом на email.
  TwoFactorPhoneValidateSms = 'twoFactorPhoneValidateSms', // Экран проверки смс кода для прохождения ДФА. Здесь пользователь вводит из смс код, чтобы подтвердить свой телефонный номер.
  TwoFactorPhoneValidateCall = 'twoFactorPhoneValidateCall', // Экран проверки номера телефона по звонку для прохождения ДФА. Здесь на указанный номер пользователя поступает телефонный звонок, а он вводит последние 4 цифры этого телефонного номера.
  TwoFactorAttemptsExhausted = 'twoFactorAttemptsExhausted',
  Restore = 'restore',
  ConfirmPhone = 'confirmPhone',
  EmailSent = 'emailSent', // Экран успешной отправки письма для подтверждения email-а при регистрации или восстановлении пароля
  RememberedAccountsSelect = 'rememberedAccountsSelect', // Экран выбора из списка аккаунтов, в которые пользователь входил ранее на устройстве
}
