import { ca } from '@cian/analytics';

// import { TPlatform } from '../../types/platform';

export const trackClickOnBanner = (platform: any) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'click',
    category: 'Open_app_button',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
  });
};
