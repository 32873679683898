import { DeferredRegistry } from './deferredRegistry';

let deferredRegistry: DeferredRegistry;

export function getDeferredRegistry() {
  if (deferredRegistry) {
    return deferredRegistry;
  }

  deferredRegistry = new DeferredRegistry();

  return deferredRegistry;
}
