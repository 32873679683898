import { IconProductSaveSearch16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackSavedSearchesIconClick } from './tracking';
import { Utility } from '../../components/Utility';
import { IApplicationState } from '../../types/redux';

export const UtilitySavedSearchesContainer: React.FC = () => {
  const savedSearchesLink = useSelector<IApplicationState, string | null>(state => state.servicesLinks.savedSearches);
  const savedSearchesCount = useSelector<IApplicationState, number>(state => state.savedSearches.count);

  if (!savedSearchesLink) {
    return null;
  }

  return (
    <Utility
      title="Сохранённые поиски"
      unread={Boolean(savedSearchesCount)}
      icon={IconProductSaveSearch16}
      href={savedSearchesLink}
      onClick={trackSavedSearchesIconClick}
    />
  );
};
