import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EMarkAllAsReadNotificationActionTypes {
  Initial = 'markAllAsReadNotification/initial',
  Loading = 'markAllAsReadNotification/loading',
  Succeed = 'markAllAsReadNotification/succeed',
  Failed = 'markAllAsReadNotification/failed',
}

export type TMarkAllAsReadNotificationInitial = ITypedReduxAction<EMarkAllAsReadNotificationActionTypes.Initial>;
export type TMarkAllAsReadNotificationLoading = ITypedReduxAction<EMarkAllAsReadNotificationActionTypes.Loading>;
export type TMarkAllAsReadNotificationSucceed = ITypedReduxAction<EMarkAllAsReadNotificationActionTypes.Succeed>;
export type TMarkAllAsReadNotificationFailed = ITypedReduxAction<EMarkAllAsReadNotificationActionTypes.Failed>;

export type TMarkAllAsReadNotification =
  | TMarkAllAsReadNotificationInitial
  | TMarkAllAsReadNotificationLoading
  | TMarkAllAsReadNotificationSucceed
  | TMarkAllAsReadNotificationFailed;
