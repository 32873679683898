import loadable from '@loadable/component';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFERRED_KEY } from './constants';
import { LoadableErrorModal } from './containers/LoadableErrorModal';
import { LoadableFallbackModal } from './containers/LoadableFallbackModal';
import { useEventsLog } from './hooks/useEventsLog';
import { getDeferredRegistry } from './utils/deferred';
import { enableLoadForm } from '../../actions/feedbackForm';
import { LoadableErrorBoundary } from '../../components/LoadableErrorBoundary';
import { selectFeedbackFormLoadEnabled } from '../../selectors/feedbackForm';
import { TThunkDispatch } from '../../types/redux';
import { useApplicationContext } from '../../utils/applicationContext';

import type { IContext } from '@cian/feedback-form-widget';

const FeedbackForm = loadable(
  () => import(/* webpackChunkName: "FeedbackFormContentContainer" */ './FeedbackFormContentContainer'),
  {
    fallback: <LoadableFallbackModal />,
    ssr: false,
  },
);

export const FeedbackFormContentContainerLoadable: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { logger, config } = useApplicationContext();
  const techProblemsBannerEnabled = config.get('feedbackForm.techProblemsBanner.enabled');
  const isFeedbackFormLoadEnabled = useSelector(selectFeedbackFormLoadEnabled);

  React.useEffect(() => {
    const registry = getDeferredRegistry();

    return () => registry.remove(DEFERRED_KEY);
  }, []);

  const widgetCallback = React.useCallback((context: IContext) => {
    const registry = getDeferredRegistry();
    const value = registry.get<IContext>(DEFERRED_KEY);

    if (!value) {
      return;
    }

    value.resolve(context);
  }, []);

  const loadAndOpenModal = React.useCallback(async () => {
    const registry = getDeferredRegistry();
    registry.createIfNotExists(DEFERRED_KEY);

    dispatch(enableLoadForm());
    await FeedbackForm.load();

    const value = registry.get<IContext>(DEFERRED_KEY);
    if (value) {
      const context = await value.promise;

      context.openModal();
    }
  }, [dispatch]);

  const handleRetry = React.useCallback(async () => {
    const registry = getDeferredRegistry();
    registry.create(DEFERRED_KEY);

    await FeedbackForm.load();

    const value = registry.get<IContext>(DEFERRED_KEY);
    if (value) {
      const context = await value.promise;

      context.openModal();
    }
  }, []);

  useEventsLog({ openModal: loadAndOpenModal });

  if (techProblemsBannerEnabled || !isFeedbackFormLoadEnabled) {
    return null;
  }

  return (
    <LoadableErrorBoundary logger={logger} onRetry={handleRetry} fallback={LoadableErrorModal}>
      <FeedbackForm widgetCallback={widgetCallback} />
    </LoadableErrorBoundary>
  );
};
