import { IFavoritesState } from '../../types/favorites';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EFavoritesActionTypes {
  UpdateUserFavorites = 'favorites/update-user-favorites',
}

export type TUpdateUserFavorites = ITypedReduxAction<EFavoritesActionTypes, IFavoritesState['favoriteItems']>;

export type TFavoritesActions = TUpdateUserFavorites;
