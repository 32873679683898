// TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
import { useSelector } from 'react-redux';

import { selectExperiments } from '../../selectors/experiments';
import { selectIsRealtor } from '../../selectors/user';
import { useApplicationContext } from '../../utils/applicationContext';
import {
  CONTROL_GROUP_TEXT_NODE,
  CONTROL_GROUP_URL_NODE,
  EXPERIMENTAL_GROUP_NAME_NODE,
  EXPERIMENTAL_GROUP_TEXT_NODE,
  EXPERIMENTAL_GROUP_URL_NODE,
  EXPERIMENT_NAME_NODE,
} from '../../utils/constants/placementTextExperimentConstants';
import { isExperimentActive } from '../../utils/experiments';

export const useGetPlacementProps = () => {
  const { config } = useApplicationContext();
  const isRealtor = useSelector(selectIsRealtor);
  const experiments = useSelector(selectExperiments);
  const experimentName = config.get<string>(EXPERIMENT_NAME_NODE);
  const experimentalGroupName = config.get<string>(EXPERIMENTAL_GROUP_NAME_NODE);

  const experimentalProps = {
    text: config.get<string>(EXPERIMENTAL_GROUP_TEXT_NODE),
    url: config.get<string>(EXPERIMENTAL_GROUP_URL_NODE),
  };

  const controlProps = {
    text: config.get<string>(CONTROL_GROUP_TEXT_NODE),
    url: config.get<string>(CONTROL_GROUP_URL_NODE),
  };

  if (isRealtor || !experimentName || !experimentalGroupName) {
    return {
      text: null,
      url: null,
    };
  }

  return isExperimentActive(experiments, experimentName, experimentalGroupName) ? experimentalProps : controlProps;
};
