import { compose } from 'redux';

interface IReduxComposeEnhancersParams {
  name: string;
}

export function createComposeEnhancers({ name }: IReduxComposeEnhancersParams) {
  if (process.env.IS_BROWSER_BUILD) {
    const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window;

    /* istanbul ignore next */
    if (__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      return __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: `${name}: ${document.title}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    }
  }

  return compose;
}
