import { ResponseError } from '../../errors';
import {
  fetchWebGetUserFavorites,
  TWebGetUserFavoritesResponse,
} from '../../repositories/favorites/v1/web/get-user-favorites';
import { IApplicationContext } from '../../types/applicationContext';

export async function getUserFavorites(context: IApplicationContext) {
  const { httpApi, logger } = context;

  try {
    const res: TWebGetUserFavoritesResponse = await fetchWebGetUserFavorites({
      httpApi,
      parameters: {},
    });

    if (res.statusCode !== 200) {
      throw new ResponseError({
        domain: 'getUserFavorites',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        message: res.response.message!,
        details: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: res.response.errors as any,
        },
      });
    }

    return res.response.favoriteEntities;
  } catch (error) {
    logger.warning(error, {
      domain: 'getUserFavorites',
      message: 'Failed get user favorites',
    });

    return [];
  }
}
