import * as React from 'react';

import * as styles from './UserInfo.css';
import { IUserAuthenticatedState } from '../../../../types/user';
import { UserAvatar } from '../../../UserAvatar';

interface IUserInfoProps {
  user: IUserAuthenticatedState;
  userCabinetUrl: string;
  onClick(): void;
}

export const UserInfo: React.FC<IUserInfoProps> = ({ user, userCabinetUrl, onClick }) => {
  const userName = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.userId;

  return (
    <a href={userCabinetUrl} className={styles['container']} onClick={onClick}>
      <div className={styles['avatar']}>
        <UserAvatar size={30} user={user} />
      </div>
      <div className={styles['user-name']}>
        {userName}
        <div className={styles['label']}>Личный кабинет</div>
      </div>
    </a>
  );
};
