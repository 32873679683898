import { Responsive } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { UserAvatar } from '../../components/UserAvatar';
import { selectUser } from '../../selectors/user';
import { IApplicationState } from '../../types/redux';
import { IUserAuthenticatedState } from '../../types/user';
import { UserMenuContainer } from '../UserMenuContainer';

export const UserContainer = () => {
  const [isActive, setIsActive] = React.useState(false);
  const user = useSelector(selectUser) as IUserAuthenticatedState;
  const userCabinetUrl = useSelector<IApplicationState, string>(state => state.userArea.userCabinetUrl);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    setIsActive(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsActive(false);
  }, []);

  return (
    <>
      <Responsive phone tablet>
        <UserAvatar size={30} href={userCabinetUrl} user={user} />
      </Responsive>
      <Responsive desktop>
        <UserAvatar size={44} href={userCabinetUrl} user={user} onClick={handleClick} />
        {isActive && <UserMenuContainer onClose={handleClose} />}
      </Responsive>
    </>
  );
};
