export const AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT = 'aud_desktop_aud2776_cian_assistant_entry_point';
export const AUD_MOBILE_CAT_PROFILING = 'aud_mobile_adsc48_cat_profiling';
export const AUD_DESKTOP_CAT_PROFILING = 'aud_desktop_adsc48_cat_profiling';
export const AUD_AUTH_TRAP = 'aud_web_adsc_99_auth_traps';

export const EXPERIMENTS = [
  AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT,
  AUD_MOBILE_CAT_PROFILING,
  AUD_DESKTOP_CAT_PROFILING,
  AUD_AUTH_TRAP,
];
