import { ERubric } from '../../types/rubricsLinks';

export const RENT = 'Аренда';
export const SALE = 'Продажа';
export const RESIDENTIAL_COMPLEXES = 'Новостройки';
export const COUNTRYSIDE = 'Строительство';
export const COMMERCIAL = 'Коммерческая';
export const MORTGAGE = 'Ипотека';
export const VALUATION = 'Оценка';
export const EXTRA = 'Ещё';
export const MY_HOME = 'Мой дом';
export const SERVICES = 'Сервисы';
export const CIAN_APP = 'Приложение Циан';

type TypedObject = {
  [key in `${ERubric}`]: string;
};

export const RUBRIC_NAMES: TypedObject = {
  [ERubric.Rent]: RENT,
  [ERubric.Sale]: SALE,
  [ERubric.ResidentialComplexes]: RESIDENTIAL_COMPLEXES,
  [ERubric.Countryside]: COUNTRYSIDE,
  [ERubric.Commercial]: COMMERCIAL,
  [ERubric.Evaluation]: VALUATION,
  [ERubric.Mortgage]: MORTGAGE,
  [ERubric.MyHome]: MY_HOME,
  [ERubric.Services]: SERVICES,
  [ERubric.CianApp]: CIAN_APP,
  [ERubric.Extra1024]: EXTRA,
  [ERubric.Extra1093]: EXTRA,
  [ERubric.Extra1242]: EXTRA,
  [ERubric.OldExtra1024]: EXTRA,
  [ERubric.OldExtra1093]: EXTRA,
};

export const NAVBAR_RUBRICS: ERubric[] = [
  ERubric.Rent,
  ERubric.Sale,
  ERubric.ResidentialComplexes,
  ERubric.Countryside,
  ERubric.Commercial,
  ERubric.Mortgage,
  ERubric.Services,
  ERubric.Extra1242,
  ERubric.Extra1093,
  ERubric.Extra1024,
];
