export enum ELogoutStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface ILogoutState {
  status: ELogoutStatus;
}
