import * as React from 'react';

import { Login } from './components/Login';

interface IAuthenticationProps {
  onAuthenticateClick(): void;
}

export const Authentication: React.FC<IAuthenticationProps> = ({ onAuthenticateClick }) => {
  const [returnUrl, setReturnUrl] = React.useState<string | null>(null);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      onAuthenticateClick();
    },
    [onAuthenticateClick],
  );

  const handleLocationChange = React.useCallback(() => {
    setReturnUrl(window.location.href);
  }, []);

  React.useEffect(() => {
    setReturnUrl(window.location.href);

    window.addEventListener('popstate', handleLocationChange);

    return () => window.removeEventListener('popstate', handleLocationChange);
  }, [handleLocationChange]);

  return <Login href={`/authenticate/${returnUrl ? `?returnUrl=${returnUrl}` : ''}`} onClick={handleClick} />;
};
