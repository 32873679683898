import { EFeedbackFormActionType } from '../../actions/feedbackForm';
import { IFeedbackFormState } from '../../types/feedbackForm';
import { TReduxActions } from '../../types/redux';

const defaultState: IFeedbackFormState = {
  loadFormEnabled: false,
};

export function feedbackFormReducer(
  state: IFeedbackFormState = defaultState,
  action: TReduxActions,
): IFeedbackFormState {
  switch (action.type) {
    case EFeedbackFormActionType.LoadFormEnabled: {
      return {
        ...state,
        loadFormEnabled: true,
      };
    }
    case EFeedbackFormActionType.LoadFormDisabled: {
      return {
        ...state,
        loadFormEnabled: false,
      };
    }
  }

  return state;
}
