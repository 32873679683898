import loadable from '@loadable/component';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearDebouncedCloseRubric, debouncedCloseRubric } from '../../actions/dropdown';
import { Dropdown } from '../../components/Dropdown';
import { LoadableErrorBoundary } from '../../components/LoadableErrorBoundary';
import { LoadableFallback } from '../../components/LoadableFallback';
import { IApplicationState } from '../../types/redux';
import { ERubric } from '../../types/rubricsLinks';
import { useApplicationContext } from '../../utils/applicationContext';

const DropdownInnerContainerLoadable = loadable(
  () => import(/* webpackChunkName: "NavBarDropdownInner" */ './DropdownInnerContainerLoadable'),
  {
    fallback: <LoadableFallback />,
    ssr: false,
  },
);

export const DropdownContainer: React.FC = () => {
  const { logger } = useApplicationContext();
  const dispatch = useDispatch();
  const open = useSelector((state: IApplicationState) => state.dropdown.open);
  const hoveredRubric = useSelector((state: IApplicationState) => state.dropdown.hoveredRubric);
  const handleRetry = React.useCallback(() => {
    DropdownInnerContainerLoadable.load();
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    dispatch(debouncedCloseRubric());
  }, [dispatch]);

  const handleMouseEnter = React.useCallback(() => {
    dispatch(clearDebouncedCloseRubric());
  }, [dispatch]);

  const isGrowDropdown = ERubric.Extra1093 === hoveredRubric;

  if (!open || !hoveredRubric) {
    return null;
  }

  return (
    <Dropdown isGrow={isGrowDropdown} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
      <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
        <DropdownInnerContainerLoadable rubric={hoveredRubric} />
      </LoadableErrorBoundary>
    </Dropdown>
  );
};
