import { Microfrontend } from '@cian/mf-react';
import * as React from 'react';

import { useChatsWidgetMicrofrontend } from './useChatsWidgetMicrofrontend';

export const ChatsWidgetContainer = () => {
  const { manifest } = useChatsWidgetMicrofrontend();

  if (!manifest) {
    return null;
  }

  return (
    <Microfrontend
      name={manifest.microfrontendName}
      runtime={manifest.runtimeName}
      image={manifest.imageVersion}
      instance={manifest.instanceId}
    />
  );
};
