import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackingFavoritesItemClick, trackingFavoritesItemShow } from './tracking';
import { getAddressString, getMetroComponent } from './utils';
import { FavoritesItem, FavoritesTooltipLayout } from '../../components/Favorites';
import { EFavoritesItemEntityType, IFavoritesState } from '../../types/favorites';
import { IApplicationState } from '../../types/redux';

interface IFavoritesTooltipContainerProps {
  target: '_blank' | '_self';
}

export const FavoritesTooltipContainer: React.FC<IFavoritesTooltipContainerProps> = ({ target }) => {
  const favoritesLink = useSelector<IApplicationState, string>(state => state.servicesLinks.favorites as string);
  const favoritesList = useSelector<IApplicationState, IFavoritesState['favoriteItems']>(
    state => state.favorites.favoriteItems,
  );

  const handleShow = React.useCallback((offerId: number, entityType: EFavoritesItemEntityType, position: number) => {
    if (entityType !== EFavoritesItemEntityType.Newbuilding) {
      trackingFavoritesItemShow(offerId, position);
    }
  }, []);

  if (!favoritesList.length) {
    return null;
  }

  return (
    <FavoritesTooltipLayout favoritesLink={favoritesLink} jkLink={favoritesLink} target={target}>
      <>
        {favoritesList.map(({ title, fullUrl, bargainTerms, photoPreviewUrl, geo, entityId, entityType }, index) => (
          <ActionAfterViewObserver
            percentVisible={0.5}
            callback={() => handleShow(entityId, entityType, index)}
            triggerOnce={false}
            callbackDelay={1000}
            key={title + index}
          >
            <FavoritesItem
              title={title}
              offerUrl={fullUrl}
              price={bargainTerms.priceText ?? null}
              imgUrl={photoPreviewUrl ?? null}
              address={getAddressString(geo.address)}
              metro={getMetroComponent(geo.undergrounds)}
              onCLick={trackingFavoritesItemClick}
            />
          </ActionAfterViewObserver>
        ))}
      </>
    </FavoritesTooltipLayout>
  );
};
