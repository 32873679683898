import { useMemo } from 'react';

import { useApplicationContext } from '../utils/applicationContext';

export const ALLOWED_CAT_PROFILING_PAGES: Record<string, RegExp | string> = {
  MAIN_PAGE: '/',
  MAIN_LANDING_PAGE: /^\/(snyat|kupit|posutochno|commercial|novostrojki)\/?/,
  SEARCH_PAGE_CAT_PHP: /^\/cat.php\/?/,
  SEARCH_PAGE_SEO_URL: /^\/(kupit|snyat)-.+\/?/,
  OFFER_CARD_PAGE: /^\/(rent|sale)\/(flat|commercial|suburban)\/(\d+)\/?/,
  NEWBUILDINGS_SEARCH: /^\/(novostroyki|newobjects\/list)\/?/,
} as const;

const DENIED_CAT_PROFILING_SUBDOMAINS: Record<string, RegExp | string> = {
  ACCOUNT_PAGE_SUBDOMAIN: 'my',
  RESIDENTIAL_COMPLEX: /^zhk-.+$/,
};

export function useAllowedCatProfilingPage(): boolean {
  const {
    custom: { originalSubdomain },
  } = useApplicationContext();

  const isAllowedPath = useMemo(() => {
    if (!process.env.IS_BROWSER_BUILD) {
      return false;
    }

    return Object.entries(ALLOWED_CAT_PROFILING_PAGES).some(([_, page]) => {
      if (typeof page === 'string') {
        return location.pathname === page;
      } else if (page instanceof RegExp) {
        return page.test(location.pathname);
      }

      return false;
    });
  }, []);

  const isAllowedSubdomain = useMemo(() => {
    return !Object.entries(DENIED_CAT_PROFILING_SUBDOMAINS).some(([_, rule]) => {
      if (rule instanceof RegExp) {
        return rule.test(originalSubdomain);
      } else {
        return rule === originalSubdomain;
      }
    });
  }, [originalSubdomain]);

  return useMemo(() => isAllowedPath && isAllowedSubdomain, [isAllowedPath, isAllowedSubdomain]);
}
