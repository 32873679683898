import * as React from 'react';

import { trackingMobileAppClick } from './tracking';
import { MobileAppLinkLayout } from '../../components/MobileAppLinkLayout';

export const MobileAppDesktopContainer: React.FC = () => {
  const handleClick = React.useCallback(() => {
    trackingMobileAppClick();
  }, []);

  return (
    <MobileAppLinkLayout onClick={handleClick}>
      <span>Приложение Циан</span>
    </MobileAppLinkLayout>
  );
};
