import * as React from 'react';

import * as styles from './RubricItem.css';

interface IRubricItemProps {
  href: string;
  onClick?(): void;
  children: React.ReactNode;
}

export const RubricItem: React.FC<IRubricItemProps> = ({ href, children, onClick }) => {
  return (
    <a className={styles['link']} href={href} onClick={onClick}>
      {children}
    </a>
  );
};
