interface IState {
  [key: string]: IValue;
}

interface IValue<V = unknown> extends ICallbacks {
  promise: Promise<V>;
}

interface ICallbacks {
  resolve: Function;
  reject: Function;
}

export class DeferredRegistry {
  private state: IState;

  public constructor() {
    this.state = {};
  }

  public get<V>(key: string): IValue<V> | undefined {
    return this.state[key] as IValue<V>;
  }

  public create<V = unknown>(key: string) {
    const callbacks: ICallbacks = {
      resolve: () => {},
      reject: () => {},
    };

    const promise = new Promise<V>((resolve, reject) => {
      callbacks.resolve = resolve;
      callbacks.reject = reject;
    });

    this.state[key] = { ...callbacks, promise };
  }

  public createIfNotExists(key: string) {
    if (this.get(key)) {
      return;
    }

    this.create(key);
  }

  public remove(key: string) {
    delete this.state[key];
  }
}
