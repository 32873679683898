import { createConsumer, createSubscriber, getGlobalEventsLogs, IEvent } from '@cian/events-log';
import * as React from 'react';

interface IParameters {
  openModal(state: boolean): void;
}

export function useEventsLog({ openModal }: IParameters) {
  React.useEffect(() => {
    const feedbackConsumer = createConsumer({ topic: 'feedback' });

    const feedbackSubscriber = createSubscriber((event: IEvent<unknown>) => {
      if (event.type === 'open') {
        openModal(true);

        const globalLog = getGlobalEventsLogs();

        globalLog.produce('feedback', { type: 'popup_opened', value: true });
      }
    });

    feedbackConsumer.subscribe(feedbackSubscriber);

    return () => {
      feedbackConsumer.unsubscribe(feedbackSubscriber);
      feedbackConsumer.destroy();
    };
  }, [openModal]);
}
