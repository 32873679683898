import * as React from 'react';

import * as styles from './Close.css';

interface ICloseProps {
  onClick(): void;
}

export const Close: React.FC<ICloseProps> = ({ onClick }) => {
  return <button className={styles['container']} onClick={onClick} />;
};
