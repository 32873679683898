import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EFeedbackFormActionType {
  LoadFormEnabled = 'feedbackForm/loadForm/enabled',
  LoadFormDisabled = 'feedbackForm/loadForm/disabled',
}

export type TLoadFormEnabled = ITypedReduxAction<EFeedbackFormActionType.LoadFormEnabled>;
export type TLoadFormDisabled = ITypedReduxAction<EFeedbackFormActionType.LoadFormDisabled>;

export type TFeedbackFormActions = TLoadFormEnabled | TLoadFormDisabled;
