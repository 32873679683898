import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EDeleteAllNotificationActionTypes {
  Initial = 'deleteAllNotifications/initial',
  Loading = 'deleteAllNotifications/loading',
  Succeed = 'deleteAllNotifications/succeed',
  Failed = 'deleteAllNotifications/failed',
}

export type TDeleteAllNotificationsInitial = ITypedReduxAction<EDeleteAllNotificationActionTypes.Initial>;
export type TDeleteAllNotificationsLoading = ITypedReduxAction<EDeleteAllNotificationActionTypes.Loading>;
export type TDeleteAllNotificationsSucceed = ITypedReduxAction<EDeleteAllNotificationActionTypes.Succeed>;
export type TDeleteAllNotificationsFailed = ITypedReduxAction<EDeleteAllNotificationActionTypes.Failed>;

export type TDeleteAllNotifications =
  | TDeleteAllNotificationsInitial
  | TDeleteAllNotificationsLoading
  | TDeleteAllNotificationsSucceed
  | TDeleteAllNotificationsFailed;
