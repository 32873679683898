import { Button } from '@cian/ui-kit/button';
import { ModalWindow } from '@cian/ui-kit/modal';
import * as React from 'react';

import * as styles from './CookiesAgreementModal.css';
import { CookiesAgreementModalContentLoadable } from './components/CookiesAgreementModalContent';
import { LoadableErrorBoundary } from '../LoadableErrorBoundary';

interface ICookiesAgreementModalProps {
  open?: boolean;
  phone?: boolean;
  onAccept(): void;
  onClose(): void;
}

export const CookiesAgreementModal: React.FC<ICookiesAgreementModalProps> = ({ open, phone, onAccept, onClose }) => {
  const handleRetryClick = React.useCallback(() => {
    CookiesAgreementModalContentLoadable.load();
  }, []);

  return (
    <ModalWindow
      open={open || false}
      onClose={onClose}
      title="Соглашение об использовании cookie-файлов"
      maxWidth={928}
      size="M"
      fixed
      content={
        <div className={styles['content']}>
          <LoadableErrorBoundary onRetry={handleRetryClick}>
            <CookiesAgreementModalContentLoadable />
          </LoadableErrorBoundary>
        </div>
      }
      footer={
        <div className={styles['footer-container']}>
          <Button onClick={onClose} theme={phone ? 'fill_secondary' : 'fill_white_primary'} fullWidth={phone}>
            Отменить
          </Button>
          <Button onClick={onAccept} theme="fill_primary" fullWidth={phone}>
            Принять
          </Button>
        </div>
      }
    />
  );
};
