import { useDeviceType } from '@cian/ui-kit';
import { useMemo } from 'react';

import { useApplicationContext } from '../utils/applicationContext';

export function usePinnedButtonEntryPoint(): boolean {
  const { config } = useApplicationContext();
  const deviceType = useDeviceType();
  const isEnabled = config.get<boolean>('PinnedButtonHeaderEntry.Enabled') ?? false;

  return useMemo(
    () => isEnabled && deviceType === 'phone',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
