import { EGeoActionType } from '../../actions/geo';
import { IGeoState } from '../../types/geo';
import { TReduxActions } from '../../types/redux';

const defaultState: IGeoState = {
  regionId: 1,
  cityId: 1,
};

export function geoReducer(state: IGeoState = defaultState, action: TReduxActions): IGeoState {
  switch (action.type) {
    case EGeoActionType.SetRegionId:
      return {
        ...state,
        regionId: action.payload,
      };
    default:
      return state;
  }
}
