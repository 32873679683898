import { UIText2 } from '@cian/ui-kit';
import { IconMapMetroMsk16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

interface IMetroItemProps {
  name: string;
  timeToDirection: string;
  metroLineColor: string;
  transportTypeIcon: React.ReactNode;
}

export const MetroItem: React.FC<IMetroItemProps> = ({ name, timeToDirection, metroLineColor, transportTypeIcon }) => {
  return (
    <div className={styles['metro-item']}>
      <div className={styles['metro-icon']} style={{ color: `#${metroLineColor}` }}>
        <IconMapMetroMsk16 color="current_color" />
      </div>

      <UIText2>{name}</UIText2>

      {transportTypeIcon}
      <UIText2>{timeToDirection}</UIText2>
    </div>
  );
};
