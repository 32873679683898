import { IConfig } from '@cian/config/shared';
import { getGlobalEventsLogs } from '@cian/events-log';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { EMessageResponseTypes, PopupManager, PushManager } from '@cian/push-manager';

import { ISetWebPushSubscribedStatusEvent } from '../events/types';

let pushManager: PushManager | null = null;
let context: IServiceContext | null = null;

interface IServiceContext {
  config: IConfig;
  httpApi: IHttpApi;
  logger: ILogger;
}

export async function subscribe() {
  if (!context || !pushManager) {
    throw new Error('init service first');
  }

  const applicationServerKey = context.config.get<string>('applicationServerKey');
  const globalEventsLog = getGlobalEventsLogs();

  if (!window.location.hostname.startsWith('www.')) {
    pushManager.showPopup({});
  } else if (applicationServerKey) {
    const popup = new PopupManager({
      workerSrc: '/push-worker.js',
      applicationServerKey,
      httpApi: context.httpApi,
    });
    await popup.register();
    await popup.subscribe();

    globalEventsLog.produce<Required<ISetWebPushSubscribedStatusEvent>>('push-subscription', {
      type: 'check-status',
      value: {
        subscribed: true,
      },
    });
  }
}

export function init(ctx: IServiceContext) {
  if (context || pushManager) {
    return;
  }

  context = ctx;

  pushManager = new PushManager({
    baseUrl: context.config.get<string>('push.browserUrl') || '//cian.ru',
    hasSubdomain: !window.location.hostname.startsWith('www.'),
  });

  window.addEventListener('message', handleReceiveMessage);

  pushManager.registerWorker();
  PushManager.ready().then(PushManager.checkSubscription);
}

export function handleReceiveMessage(e: MessageEvent) {
  const globalEventsLog = getGlobalEventsLogs();

  try {
    const data = typeof e.data === 'string' ? JSON.parse(e.data) : {};

    if (data.type === EMessageResponseTypes.subscriptionStatus && !data.value) {
      globalEventsLog.produce<Required<ISetWebPushSubscribedStatusEvent>>('push-subscription', {
        type: 'check-status',
        value: {
          subscribed: Boolean(data.value),
        },
      });
    }
  } catch (err) {
    context?.logger.debug('Failed to parse message', {
      domain: 'service.webPush.handleReceiveMessage',
      data: e.data,
    });
  }
}
