// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IAgentSchema } from '../agent/AgentSchema';
import type { IBargainTermsForDesktopSchema } from './BargainTermsForDesktopSchema';
import type { IClientReactionsToOfferSchema } from './ClientReactionsToOfferSchema';
import type { ICommercialInfoSchema } from './CommercialInfoSchema';
import type { ICommercialOwnershipSchema } from '../commercial_ownership/CommercialOwnershipSchema';
import type { IComparisonStatusInfoSchema } from '../comparison_status_info/ComparisonStatusInfoSchema';
import type { IExtendedDataSchema } from '../extended_data/ExtendedDataSchema';
import type { IFlatInfoSchema } from './FlatInfoSchema';
import type { IGeoForDesktopSchema } from './GeoForDesktopSchema';
import type { IJKInfoSchema } from './JKInfoSchema';
import type { IModerationInfoForDesktopSchema } from '../moderation_info/ModerationInfoForDesktopSchema';
import type { ISuburbanInfoSchema } from './SuburbanInfoSchema';

export interface IFavoriteEntityForDesktopSchema {
  /** Информация об агенте **/
  agentInfo?: IAgentSchema;
  /** Информация о цене **/
  bargainTerms: IBargainTermsForDesktopSchema;
  /** ИД категории для определния типа жалоб **/
  categoryComplaintId?: number;
  /** Реакции клиента на объявление в расшаренной подборке **/
  clientReactions: IClientReactionsToOfferSchema;
  /** Информация о коммерческой недвижимости **/
  commercialInfo?: ICommercialInfoSchema;
  /** Данные о собственнике коммерческой недвижимости **/
  commercialOwnership?: ICommercialOwnershipSchema;
  /** Предпочитаемый тип связи **/
  communicationType?: ECommunicationType;
  /** Статус добавления объявления в сравнение **/
  comparisonStatus?: IComparisonStatusInfoSchema;
  /** Дополнительные данные для объявлений посутки **/
  dailyrent?: IExtendedDataSchema;
  /** Тип сделки **/
  dealType: EDealType;
  /** Дата когда объявление удалится, только для снятых с публикации **/
  deleteDate?: string;
  /** Описание **/
  description?: string;
  /** Cian ID объявления **/
  entityCianId?: number;
  /** Realty Id объекта (объявка/ЖК) **/
  entityId: number;
  /** Тип объекта (объявка/ЖК) **/
  entityType: EEntityType;
  /** Ссылка на объявление на партнерском сайте **/
  externalOfferUrl?: string;
  /** Информация о жилой недвижимости **/
  flatInfo?: IFlatInfoSchema;
  /** Ссылка на объект **/
  fullUrl: string;
  /** Информация о Гео **/
  geo: IGeoForDesktopSchema;
  /** Является ли объявление партнерским **/
  isCianPartner?: boolean;
  /** Проверено ли объявление в росреестре **/
  isRosreestrCheckSuccess?: boolean;
  /** Информация о ЖК **/
  jkInfo?: IJKInfoSchema;
  /** Данные модерации **/
  moderationInfo?: IModerationInfoForDesktopSchema;
  /** Текст заметки **/
  note?: string;
  /** Телефон **/
  phone?: string;
  /** URL исходного изображения **/
  photoFullUrl?: string;
  /** URL превью изображения **/
  photoPreviewUrl?: string;
  /** Показывать ли предупреждение о недопустимости предоплаты **/
  showWarningMessage?: boolean;
  /** Статус **/
  status?: EStatus;
  /** Информация о загородной недвижимости **/
  suburbanInfo?: ISuburbanInfoSchema;
  /** Заголовок объекта **/
  title: string;
  /** Заголовок объекта для мобильного сайта **/
  titleForMobileSite?: string;
  /** Ссылка на платформу электронных торгов **/
  tradingLink?: string;
}

export enum ECommunicationType {
  /** С помощью анкет **/
  'Forms' = 'forms',
  /** По телефону **/
  'Phone' = 'phone',
}
export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Продажа **/
  'Sale' = 'sale',
}
export enum EEntityType {
  /** Коммерческая **/
  'Commercial' = 'commercial',
  /** ЖК **/
  'Newbuilding' = 'newbuilding',
  /** Жилая **/
  'Residential' = 'residential',
  /** Загородка **/
  'Suburban' = 'suburban',
}
export enum EStatus {
  /** Заблокировано **/
  'Blocked' = 'blocked',
  /** Деактивировано **/
  'Deactivated' = 'deactivated',
  /** Удалено **/
  'Deleted' = 'deleted',
  /** Черновик **/
  'Draft' = 'draft',
  /** Склеен с другим ЖК **/
  'Merge' = 'merge',
  /** Промодерировано **/
  'Moderate' = 'moderate',
  /** Новый, создан пользователем **/
  'New' = 'new',
  /** Опубликовано **/
  'Published' = 'published',
  /** Отклонено **/
  'Refused' = 'refused',
  /** Удалено модератором **/
  'RemovedByModerator' = 'removedByModerator',
  /** Продано **/
  'Sold' = 'sold',
}
