import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';
import { INavbarLink } from '../../../types/navbar';
import { ERubric } from '../../../types/rubricsLinks';

interface INavBarProps {
  regionId: number;
  excludeSuburbanRegionsIds: number[];
  isAuthenticated: boolean;
  rubricsLinks: INavbarLink[];
  specialPromo: React.ReactNode;

  onItemMouseOver(rubric: ERubric): void;

  onItemMouseOut(): void;
}

export const NavBar: React.FC<INavBarProps> = ({
  regionId,
  excludeSuburbanRegionsIds,
  rubricsLinks,
  specialPromo,
  isAuthenticated,
  onItemMouseOut,
  onItemMouseOver,
}) => {
  const isWideSpecialPromo = excludeSuburbanRegionsIds.includes(regionId) && specialPromo;

  return (
    <nav
      className={clsx(
        styles['navigation'],
        isAuthenticated && styles['is-authenticated'],
        isWideSpecialPromo && styles['is-wide-special-promo'],
        specialPromo && styles['has-special-promo'],
      )}
      data-testid="navbar-redesign"
    >
      <ul className={styles['list']}>
        {rubricsLinks.map(({ rubric, active, hovered, title, component, url }) => {
          if (component) {
            return (
              <li key={rubric} className={styles['list-item']}>
                {component}
              </li>
            );
          }

          return (
            <li
              key={rubric}
              data-testid="nav-item"
              className={clsx({
                [styles['list-item']]: true,
                [styles['active']]: active,
                [styles['hovered']]: hovered,
                // для регионов с длинными названиями спецпроектов мы не показываем раздел Дома и участки
                // (из-за того, что в хэдере нет места)
                [styles['invisible']]: excludeSuburbanRegionsIds.includes(regionId) && rubric === ERubric.Countryside,
              })}
              onMouseOver={() => onItemMouseOver(rubric)}
              onMouseOut={onItemMouseOut}
            >
              {url && (
                <a className={styles['link']} href={url}>
                  {title}
                </a>
              )}
              {!url && (
                <span data-testid="title" className={styles['link']}>
                  {title}
                </span>
              )}
            </li>
          );
        })}
      </ul>
      {specialPromo && <div className={styles['special-promos']}>{specialPromo}</div>}
    </nav>
  );
};
