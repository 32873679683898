import { ca } from '@cian/analytics';

export function trackingFavoritesItemClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'to_card',
    label: 'favorites_popup',
  });
}

export function trackingFavoritesItemShow(offerId: number, position: number) {
  ca('teaser', {
    product: {
      id: offerId,
      name: 'show_card',
      extra: {
        offerType: 'offer',
      },
      position: position + 1,
      screen: '',
      block: 'FavoritesHeart',
    },
  });
}
