import { useBooleanState } from '@cian/react-utils';
import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { FeedbackFormTechProblemsLoadable } from '../../../../components/FeedbackFormTechProblems';
import { LoadableErrorBoundary } from '../../../../components/LoadableErrorBoundary';
import { useApplicationContext } from '../../../../utils/applicationContext';
import { useEventsLog } from '../../hooks/useEventsLog';

export const FeedbackFormTechProblemsContainer: React.VFC = () => {
  const { logger, config } = useApplicationContext();
  const { state: open, setTrue: handleOpenModal, setFalse: handleCloseModal } = useBooleanState(true);
  const techProblemsBannerEnabled = config.get('feedbackForm.techProblemsBanner.enabled');

  useEventsLog({ openModal: handleOpenModal });

  const handleRetry = React.useCallback(() => {
    FeedbackFormTechProblemsLoadable.load();
  }, []);

  if (!techProblemsBannerEnabled) {
    return null;
  }

  return (
    <ModalWindow
      fixed
      open={open}
      onClose={handleCloseModal}
      title="По техническим причинам форма обратной связи недоступна"
      width="100%"
      maxWidth={540}
      content={
        <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
          <FeedbackFormTechProblemsLoadable />
        </LoadableErrorBoundary>
      }
      escape={false}
      size="M"
    />
  );
};
