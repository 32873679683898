import * as React from 'react';

import * as styles from './Utilities.css';

interface IUtilitiesProps {
  testId: string;
  children: React.ReactNode;
}

export const Utilities: React.FC<IUtilitiesProps> = ({ testId, children }) => {
  return (
    <div data-testid={testId} className={styles['container']}>
      {children}
    </div>
  );
};
