import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { debouncedCloseRubric, debouncedOpenRubric } from '../../../actions/dropdown';
import { NavBar } from '../../../components/NavBar/Redesign';
import { selectRegionId } from '../../../selectors/geo';
import { selectPageType } from '../../../selectors/page';
import { selectHasSpecialPromo } from '../../../selectors/specialPromos';
import { selectIsAuthenticated } from '../../../selectors/user';
import { ERubric } from '../../../types/rubricsLinks';
import { useApplicationContext } from '../../../utils/applicationContext';
import { SpecialPromosDesktopContainer } from '../../SpecialPromosDesktopContainer/SpecialPromosDesktopContainer';
import { useGetRubrics } from '../utils';

export const NavBarContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const dispatch = useDispatch();
  const hasSpecialPromo = useSelector(selectHasSpecialPromo);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const regionId = useSelector(selectRegionId);
  const excludeSuburbanRegionsIds = config.get<number[]>('headerFrontend.excludeSuburbanRegions') || [];
  const pageType = useSelector(selectPageType);
  const navbarRubrics = useGetRubrics();

  const handleRubricMouseOver = React.useCallback(
    (rubric: ERubric) => {
      /** SPEC-395 */
      if (pageType === 'addFormBdui' || rubric === ERubric.MyHome) {
        return;
      }

      dispatch(debouncedOpenRubric(rubric));
    },
    [dispatch, pageType],
  );

  const handleRubricMouseOut = React.useCallback(() => {
    dispatch(debouncedCloseRubric());
  }, [dispatch]);

  const specialPromo = React.useMemo(
    () => (hasSpecialPromo ? <SpecialPromosDesktopContainer /> : null),
    [hasSpecialPromo],
  );

  return (
    <NavBar
      regionId={regionId}
      excludeSuburbanRegionsIds={excludeSuburbanRegionsIds}
      rubricsLinks={navbarRubrics}
      isAuthenticated={isAuthenticated}
      onItemMouseOut={handleRubricMouseOut}
      onItemMouseOver={handleRubricMouseOver}
      specialPromo={specialPromo}
    />
  );
};
