import { useDeviceType } from '@cian/ui-kit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAllowedCatProfilingPage } from '../../hooks/useAllowedCatProfilingPage';
import { useIsBannerSkippedByCookie } from '../../hooks/useIsBannerSkippedByCookie';
import { useNewMobileAppBannerEntryPoint } from '../../hooks/useNewMobileAppBannerEntryPoint';
import { selectMobileBannerCookieState } from '../../selectors/cookies/selectMobileBannerCookieState';
import { selectOnboardingCookieState } from '../../selectors/cookies/selectOnboardingCookieState';
import { selectTestOnboarding } from '../../selectors/onboarding/selectTestOnboarding';
import { selectShouldShowDesktopProfilingBanner } from '../../selectors/profiling/selectShouldShowDesktopProfilingBanner';
import { selectShouldShowMobileProfilingBanner } from '../../selectors/profiling/selectShouldShowMobileProfilingBanner';
import { useApplicationContext } from '../../utils/applicationContext';

export function useIsOnboardingEnabled(): boolean {
  const { config } = useApplicationContext();
  const testOnboarding = useSelector(selectTestOnboarding);
  const hasOnboardingCookie = useSelector(selectOnboardingCookieState);
  const deviceType = useDeviceType();
  const skippedBanner = useIsBannerSkippedByCookie();
  const isWebView = config.get<boolean>('web.isWebView') || false;
  const mobileBannerEnabled = config.get<boolean>('mobileBanner.enabled') ?? false;
  const headerEntryPointEnabled = useNewMobileAppBannerEntryPoint();

  const mobileBannerShown = useSelector(selectMobileBannerCookieState);
  const shouldShowMobileCatOnboardingBanner = useSelector(selectShouldShowMobileProfilingBanner);
  const shouldShowDesktopProfilingBanner = useSelector(selectShouldShowDesktopProfilingBanner);

  const shouldShowFullscreenBanner = useMemo(() => {
    return mobileBannerEnabled && headerEntryPointEnabled && !mobileBannerShown;
  }, [mobileBannerEnabled, headerEntryPointEnabled, mobileBannerShown]);

  // переиспользуем хук, т.к. он проверяет разрешенные страницы для профилирования
  // TODO: доработать после раскатки/выпиливания профилирования
  const allowedPage = useAllowedCatProfilingPage();

  const isDisabledOnDesktop = useMemo(
    () => deviceType === 'desktop' && shouldShowDesktopProfilingBanner,
    [deviceType, shouldShowDesktopProfilingBanner],
  );

  const isDisabledOnMobile = useMemo(
    () => deviceType === 'phone' && (shouldShowMobileCatOnboardingBanner || shouldShowFullscreenBanner),
    [deviceType, shouldShowFullscreenBanner, shouldShowMobileCatOnboardingBanner],
  );

  const isDisabled = useMemo(
    () => skippedBanner || isWebView || isDisabledOnDesktop || isDisabledOnMobile,
    [isDisabledOnDesktop, isDisabledOnMobile, isWebView, skippedBanner],
  );

  return useMemo(
    () =>
      (config.get<boolean>('BX.WebOnboarding.Enabled') ?? false) &&
      !isDisabled &&
      allowedPage &&
      (!hasOnboardingCookie || testOnboarding !== null),
    [config, isDisabled, allowedPage, hasOnboardingCookie, testOnboarding],
  );
}
