import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './Dropdown.css';

interface IDropdownProps {
  onMouseLeave(): void;
  onMouseEnter(): void;
  isGrow: boolean;
  children: React.ReactNode;
}

export const Dropdown: React.FC<IDropdownProps> = ({ onMouseLeave, onMouseEnter, isGrow, children }) => (
  <div
    data-testid="dropdown"
    className={clsx(styles['container'], isGrow && styles['is-grow'])}
    onMouseLeave={onMouseLeave}
    onMouseEnter={onMouseEnter}
  >
    {children}
  </div>
);
