import { markAllAsReadFailed } from './markAllAsReadFailed';
import { markAllAsReadLoading } from './markAllAsReadLoading';
import { markAllAsReadSucceed } from './markAllAsReadSucceed';
import { fetchDesktopMarkAllAsRead } from '../../../repositories/notification-center/v1/desktop/mark-all-as-read';
import { TThunkAction } from '../../../types/redux';

export const markAllAsRead = (): TThunkAction<Promise<void>> => {
  return async (dispatch, _getState, context) => {
    const { httpApi, logger } = context;

    dispatch(markAllAsReadLoading());
    try {
      await fetchDesktopMarkAllAsRead({
        httpApi,
      });

      dispatch(markAllAsReadSucceed());
    } catch (ex) {
      logger.error(ex, {
        domain: 'markAsReadNotification',
      });

      dispatch(markAllAsReadFailed());
    }
  };
};
