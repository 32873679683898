import { IMicrofrontendManifest } from '@cian/mf-registry/shared';
import { useEffect, useState } from 'react';

import { getChatsWidgetMicrofrontend } from '../../services/getChatsWidgetMicrofrontend';
import { useApplicationContext } from '../../utils/applicationContext';
import { checkCianMfRegistry } from '../../utils/checkCianMfRegistry';

export const useChatsWidgetMicrofrontend = () => {
  const context = useApplicationContext();
  const [manifest, setManifest] = useState<IMicrofrontendManifest | null>(null);
  const availableUrl = context.config.get<string[]>('chatsWidget.Enabled');

  useEffect(() => {
    if (availableUrl?.includes(location.pathname) && checkCianMfRegistry()) {
      getChatsWidgetMicrofrontend(context).then(manifest => {
        setManifest(manifest);
      });
    }
  }, []);

  return { manifest };
};
