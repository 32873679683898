import { EMyHomeActionTypes } from '../../actions/myHome';
import { IMyHomeState } from '../../types/myHome';
import { TReduxActions } from '../../types/redux';

const defaultState: IMyHomeState = {
  isExist: false,
};

export function myHomeReducer(state: IMyHomeState = defaultState, action: TReduxActions): IMyHomeState {
  switch (action.type) {
    case EMyHomeActionTypes.UpdateIsExist:
      return {
        isExist: action.payload,
      };
    default:
      return state;
  }
}
