import { setCookie } from '@cian/utils/lib/browser/cookie';

import { LOGIN_BUTTON_TOOLTIP_KEY } from '../../constants/cookies';

export function setTooltipCloseCookies() {
  const expires = 1000 * 60 * 60 * 24 * 30;
  const today = new Date();

  setCookie(LOGIN_BUTTON_TOOLTIP_KEY, '1', {
    domain: '.cian.ru',
    secure: false,
    expires: new Date(today.getTime() + expires),
  });
}
