import { Avatar } from '@cian/ui-kit';
import * as React from 'react';

import { IUserAuthenticatedState } from '../../types/user';

interface IUserAvatarProps {
  size: number;
  user: IUserAuthenticatedState;
  href?: string;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
}

export const UserAvatar: React.FC<IUserAvatarProps> = ({ size, user, href, onClick }) => {
  return <Avatar type="user" src={user.avatarUrl} size={size} href={href} onClick={onClick} />;
};
