import { IApplicationState } from '../../types/redux';
import { ISpecialPromo } from '../../types/specialPromos';

export function selectCurrentSpecialPromo(state: IApplicationState): ISpecialPromo | null {
  return state.specialPromos.items.find(specialPromo => specialPromo.regionId === state.geo.regionId) || null;
}

export function selectHasSpecialPromo(state: IApplicationState): boolean {
  return selectCurrentSpecialPromo(state) !== null;
}
