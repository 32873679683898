import loadable from '@loadable/component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { LoadableErrorBoundary } from '../../components/LoadableErrorBoundary';
import { LoadableFallback } from '../../components/LoadableFallback';
import { IMobileNavigationProps, MobileNavigation } from '../../components/MobileNavigation';
import { selectUser } from '../../selectors/user';
import { useApplicationContext } from '../../utils/applicationContext';

type TMobileNavigationContainerProps = Pick<IMobileNavigationProps, 'active' | 'onClose'>;

const MobileNavigationInnerLoadable = loadable(
  () => import(/* webpackChunkName: "MobileNavigationInner" */ './loadable'),
  {
    fallback: <LoadableFallback />,
    ssr: false,
  },
);

export const MobileNavigationContainer: React.FC<TMobileNavigationContainerProps> = ({ active: isActive, onClose }) => {
  const { logger } = useApplicationContext();
  const user = useSelector(selectUser);

  const handleRetry = React.useCallback(() => {
    MobileNavigationInnerLoadable.load();
  }, []);

  return (
    <MobileNavigation active={isActive} onClose={onClose}>
      <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
        <MobileNavigationInnerLoadable active={isActive} isAuthenticated={user.isAuthenticated} onClose={onClose} />
      </LoadableErrorBoundary>
    </MobileNavigation>
  );
};
