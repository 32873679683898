import { ITypedReduxAction } from '../../types/redux/actionType';
import { ERubric } from '../../types/rubricsLinks';

export enum EDropdownActionType {
  Open = 'dropdown/open',
  Close = 'dropdown/close',
  SetHoveredRubric = 'dropdown/set-hovered-rubric',
  SetActiveCloseTimeout = 'dropdown/set-active-close-timeout',
  SetActiveOpenTimeout = 'dropdown/set-active-open-close-timeout',
}

export type TOpenDropdown = ITypedReduxAction<EDropdownActionType.Open>;
export type TCloseDropdown = ITypedReduxAction<EDropdownActionType.Close>;
export type TSetHoveredDropdownRubric = ITypedReduxAction<EDropdownActionType.SetHoveredRubric, ERubric | null>;
export type TSetActiveCloseDropdownTimeout = ITypedReduxAction<
  EDropdownActionType.SetActiveCloseTimeout,
  number | null
>;
export type TSetActiveOpenDropdownTimeout = ITypedReduxAction<EDropdownActionType.SetActiveOpenTimeout, number | null>;

export type TDropdownActions =
  | TOpenDropdown
  | TCloseDropdown
  | TSetHoveredDropdownRubric
  | TSetActiveCloseDropdownTimeout
  | TSetActiveOpenDropdownTimeout;
