import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './Logo.css';

interface ILogoProps {
  url: string;
  noResize: boolean;
  src: string | null;
}

export const Logo: React.FC<ILogoProps> = ({ noResize, url, src }) => {
  const style = React.useMemo(
    () => (src ? { backgroundImage: `url('${src}')`, backgroundPosition: 'unset' } : undefined),
    [src],
  );

  return <a href={url} className={clsx(styles['container'], noResize && styles['static'])} style={style} />;
};
