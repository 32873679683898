import * as React from 'react';
import { useSelector } from 'react-redux';

import { AuthenticationAlert } from '../../components/Authentication';
import { EAlertType } from '../../types/authentication';
import { IApplicationState } from '../../types/redux';

interface IAuthenticationAlertContainerProps {
  onClose(): void;
}

export function AuthenticationAlertContainer({ onClose }: IAuthenticationAlertContainerProps) {
  const alert = useSelector<IApplicationState, EAlertType | null>(state => state.authentication.alert);

  const alertText = React.useMemo<string | null>(() => {
    let text: string | null = null;

    switch (alert) {
      case EAlertType.Chats:
        text = 'Войдите, чтобы написать сообщение';
        break;

      case EAlertType.Favorites:
        text = 'Войдите, чтобы добавлять в избранное';
        break;

      case EAlertType.Hide:
        text = 'Войдите, чтобы скрывать объявления';
        break;

      default:
        return null;
    }

    return text;
  }, [alert]);

  if (!alertText) {
    return null;
  }

  return <AuthenticationAlert text={alertText} onClose={onClose} />;
}
