// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IUndergroundInfoForDesktopSchema {
  /** Полный url для перехода к поиску по этому гео **/
  fullUrl?: string;
  /** Является ли это метро основным для объекта **/
  isDefault: boolean;
  /** Цвет линии метро, hex **/
  lineColor?: string;
  /** Название **/
  name: string;
  /** Год введения в эксплуатацию (заполняется для строящихся) **/
  releaseYear?: number;
  /** Время в пути в минутах до метро, мин **/
  time?: number;
  /** Способ передвижения до метро **/
  transportType?: ETransportType;
  /** Строящееся **/
  underConstruction: boolean;
}

export enum ETransportType {
  /** Транспортом **/
  'Transport' = 'transport',
  /** Пешком **/
  'Walk' = 'walk',
}
