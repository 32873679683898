import { useDisablePageScroll } from '@cian/ui-kit/internal/hooks/useDisablePageScroll';
import * as React from 'react';

import { Hamburger } from '../../components/Hamburger';
import { MobileNavigationContainer } from '../MobileNavigationContainer';

export const HamburgerContainer: React.FC = () => {
  const [isTouched, setIsTouched] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  useDisablePageScroll(isActive);

  const handleMouseDown = React.useCallback(() => {
    if (isTouched) {
      return;
    }

    setIsTouched(true);
  }, [isTouched]);

  const handleClick = React.useCallback(() => {
    setIsActive(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsActive(false);
  }, []);

  return (
    <>
      <Hamburger onMouseDown={handleMouseDown} onClick={handleClick} />
      {isTouched && <MobileNavigationContainer active={isActive} onClose={handleClose} />}
    </>
  );
};
