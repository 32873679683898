import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { registry as getRegistry } from '@cian/mf-registry/browser';
import { IRegistry } from '@cian/mf-registry/shared';
import { getTelemetry } from '@cian/telemetry/browser';

import { IApplicationContext, IApplicationContextCustom } from '../../../shared/types/applicationContext';
import { checkCianMfRegistry } from '../../../shared/utils/checkCianMfRegistry';

export function createApplicationContext(): IApplicationContext {
  const config = getConfig();

  return {
    config,
    logger: getLogger(),
    httpApi: getHttpApi(),
    telemetry: getTelemetry(),
    custom: {
      ...config.getStrict<IApplicationContextCustom>('applicationContext.custom'),
    },
    //проверяем наличие реестра, то возвращаем его, иначе пустой объект
    microfrontendsRegistry: checkCianMfRegistry() ? getRegistry() : ({} as IRegistry),
  };
}
