import * as React from 'react';

import * as styles from './Inner.css';

interface IInnerProps {
  application: JSX.Element;
  authentication: JSX.Element;
  rubrics: JSX.Element;
  services: JSX.Element;
  logout: JSX.Element;
  active?: boolean;
  isAuthenticated: boolean;
}

export const Inner: React.FC<IInnerProps> = ({
  application,
  authentication,
  rubrics,
  services,
  logout,
  active: isActive,
  isAuthenticated,
}) => {
  const navRef = React.createRef<HTMLElement>();

  React.useEffect(() => {
    if (!isActive || !navRef.current) {
      return;
    }

    navRef.current.scrollTo(0, 0);
  }, [isActive, navRef]);

  return (
    <nav ref={navRef} className={styles['navigation']}>
      <ul className={styles['list']}>
        <li>{authentication}</li>
        <li>{services}</li>
        <li>{rubrics}</li>
        {isAuthenticated && <li>{logout}</li>}
        <li>{application}</li>
      </ul>
    </nav>
  );
};
