import { EHeaderStateActionType } from '../../actions/headerState';
import { TReduxActions } from '../../types/redux';
import { TUserState } from '../../types/user';

const defaultState: TUserState = {
  isAuthenticated: false,
};

export function userReducer(state: TUserState = defaultState, action: TReduxActions): TUserState {
  switch (action.type) {
    case EHeaderStateActionType.Update:
      return {
        ...action.payload.user,
      };
    default:
      return state;
  }
}
